import { Injectable, OnDestroy } from '@angular/core';
// import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { defer, NEVER, Subscription } from 'rxjs';
import { finalize, share } from 'rxjs/operators';
import { SpinnerOverlayComponent } from '../shared/components/spinner-overlay/spinner-overlay.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class SpinnerOverlayService implements OnDestroy {
  // private overlayRef: OverlayRef | undefined;
  private _activeModal!: NgbModalRef;
  disableSpinnerSub: Subscription | undefined;
  spinnerDisabled: boolean = false;

  constructor(
    // private readonly overlay: Overlay,
    private modalService: NgbModal,
    private dataService: DataService
  ) {
    this.disableSpinnerSub = this.dataService.crntDisableSpinner.subscribe(state => this.spinnerDisabled = state);
  }

  ngOnDestroy(): void {
    this.disableSpinnerSub?.unsubscribe();
  }

  public readonly spinner$ = defer(() => {
    this.show();
    return NEVER.pipe(
      finalize(() => {
        this.hide();
      })
    );
  }).pipe(share());

  private show(): void {
    Promise.resolve(null).then(() => {
      if (!this.spinnerDisabled)
        this.openLoadingModal();
      // this.overlayRef = this.overlay.create({
      //   positionStrategy: this.overlay
      //     .position()
      //     .global()
      //     .centerHorizontally()
      //     .centerVertically(),
      //   hasBackdrop: true,
      // });
      // this.overlayRef.attach(new ComponentPortal(SpinnerOverlayComponent));
    });
  }

  private hide(): void {
    this.closeModal();
    this.dataService.changeDisableSpinner(false);
    // this.overlayRef?.detach();
    // this.overlayRef = undefined;
  }

  openLoadingModal(data?: { currentUI?: string }) {
    this._activeModal = this.modalService.open(SpinnerOverlayComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });

    if (data && data.currentUI) {
      this._activeModal.componentInstance.currentUI = data.currentUI;
    }
  }

  closeModal() {
    this._activeModal?.close();
  }

  closeAllModals() {
    this.dataService.changeDisableSpinner(false);
    this.modalService?.dismissAll();
  }

}
