import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { HeaderComponent } from './navigation/header/header.component';
import { FooterComponent } from './navigation/footer/footer.component';
import { LayoutComponent } from './layout/layout.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CountdownModule } from 'ngx-countdown';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NetworkInterceptor } from './interceptors/network.interceptor';
import { OverlayModule } from '@angular/cdk/overlay';
import { ContentAnimateDirective } from './content-animate/content-animate.directive';
import { CountdownFormatPipe } from '../shared/pipes/countdownFormat.pipe';
import { GlobalErrorHandler } from './errors/global-error-handler';
import { NgOtpInputModule } from 'ng-otp-input';
import { DownloadBannerComponent } from './components/download-banner/download-banner.component';
import { NotificationWrapperComponent } from './components/notification-wrapper/notification-wrapper.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { BottomNavbarComponent } from './navigation/bottom-navbar/bottom-navbar.component';
// import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    SignInComponent,
    HeaderComponent,
    FooterComponent,
    LayoutComponent,
    ContentAnimateDirective,
    CountdownFormatPipe,
    DownloadBannerComponent,
    AutofocusDirective,
    SnackbarComponent,
    NotificationWrapperComponent,
    AutofocusDirective,
    BottomNavbarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CountdownModule,
    OverlayModule,
    // NgSelectModule,
    NgOtpInputModule
  ],
  exports: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ]
})
export class CoreModule { }
