import { Injectable } from '@angular/core';
import { Address } from '../models/address.model';
import * as firebase from 'firebase/app';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocaldataService {

  defaultLatLng = {
    lat: 25.042853,
    lng: 55.241818
  };
  quickLinkList: any[] = [
    {
      title: 'Cleaning Service',
      subTitle: 'Vetted & trained Zappers to get your cleaning needs sorted',
      items: [
        {
          id: environment.production ? 'rEMhqCyXcIGrcMIYI6fT' : 'T3URjFbydE3tlB4LYC9Q',
          code: "Q012",
          newType: 12,
          subCategoryName: "Maid Service",
          titleTop: 'Hourly Maid',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/cleaning/img01.webp'
        },
        {
          id: environment.production ? '0aX7csx75MLUA575xctw' : 'WmrxiUjxUGORT8iJ1jb9',
          code: "Q008",
          newType: 8,
          subCategoryName: "Deep Cleaning Service",
          titleTop: 'Deep Cleaning',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/cleaning/img02.webp'
        },
        {
          id: environment.production ? 'wklldrKKudZFZ1qyeTmS' : 'VeqRdZMJmPZgTILp3dVC',
          code: "Q001",
          newType: 3,
          subCategoryName: "AC Cleaning",
          titleTop: 'Air Conditioner',
          titleBtm: 'Cleaning',
          imgUrl: 'assets/images/quick-link-images/cleaning/img03.webp'
        },
        {
          id: environment.production ? '' : '',
          code: 'Q013',
          newType: 302,
          subCategoryName: "Carpet Cleaning",
          titleTop: 'Carpet Cleaning',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/cleaning/img04.webp'
        },
        {
          id: environment.production ? '0IxEndv4inQbLpQBrihu' : 'bxYsSzS2gDWD9D1RNmHP',
          code: "Q013",
          newType: 13,
          subCategoryName: "Furniture Cleaning",
          titleTop: 'Sofa Cleaning',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/cleaning/img05.webp'
        },
        {
          id: environment.production ? '' : '',
          code: 'Q012',
          newType: 303,
          subCategoryName: "Maid Service",
          titleTop: 'Home Cleaning',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/cleaning/img06.webp'
        },
        {
          id: environment.production ? '' : '',
          code: 'Q012',
          newType: 304,
          subCategoryName: "Maid Service",
          titleTop: 'Cleaning Company',
          titleBtm: 'in Dubai',
          imgUrl: 'assets/images/quick-link-images/cleaning/img07.webp'
        }
      ]
    },
    {
      title: 'Home Maintenance',
      subTitle: 'Relax and leave your worries to us',
      items: [
        {
          id: environment.production ? 'Dw6hz9hIixd44X4YyNbe' : 'AZgTJfVipSqklk5CllDT',
          code: "Q011",
          newType: 11,
          subCategoryName: "Pest Control",
          titleTop: 'Pest Control',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/home/img01.webp'
        },
        {
          id: environment.production ? 'qvCHdQxy7ghcgFXdvGFS' : 'GAV5uHV2VO4em1uKmi32',
          code: "Q017",
          newType: 17,
          subCategoryName: "Handyman Services",
          titleTop: 'Handyman',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/home/img02.webp'
        },
        {
          id: environment.production ? 'lWXnTkbrXR1yw32QMhd9' : 'NRy8fcHUZzSXmd6DyINH',
          code: "Q018",
          newType: 18,
          subCategoryName: "Plumber Services",
          titleTop: 'Plumbing',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/home/img03.webp'
        },
        {
          id: environment.production ? 'Fu3irfO4Nbjmy3y9rc0S' : '1ihajx7PfuMZ01wOV55I',
          code: "Q019",
          newType: 19,
          subCategoryName: "Electrician Services",
          titleTop: 'Electrician',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/home/img04.webp'
        },
        {
          id: environment.production ? '' : '',
          code: 'Q017',
          newType: 301,
          subCategoryName: "Carpenter Services",
          titleTop: 'Carpenter',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/home/img05.webp'
        },
        {
          id: environment.production ? 'wklldrKKudZFZ1qyeTmS' : 'VeqRdZMJmPZgTILp3dVC',
          code: "Q001",
          newType: 5,
          subCategoryName: "AC Cleaning Services",
          titleTop: 'AC Cleaning',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/home/img06.webp'
        },
      ]
    },

    {
      title: 'Salon at Home',
      subTitle: 'Pamper yourself at home',
      isExtended: true,
      items: [
        {
          id: environment.production ? 'm0Oyqwi0PlI3nT5mAcmK' : '7hLC87HZvNMNiK5tm3yD',
          code: "Q016",
          newType: 16,
          subCategoryName: "Salon For Women",
          tag: 'Save More',
          titleTop: 'Salon',
          titleBtm: 'Packages',
          imgUrl: 'assets/images/quick-link-images/salon/img01.webp'
        },
        {
          id: environment.production ? 'm0Oyqwi0PlI3nT5mAcmK' : '7hLC87HZvNMNiK5tm3yD',
          code: "Q016",
          newType: 16,
          subCategoryName: "Salon For Women",
          titleTop: 'Hair',
          titleBtm: 'Studio',
          imgUrl: 'assets/images/quick-link-images/salon/img02.webp'
        },
        {
          id: environment.production ? 'm0Oyqwi0PlI3nT5mAcmK' : '7hLC87HZvNMNiK5tm3yD',
          code: "Q016",
          newType: 16,
          subCategoryName: "Salon For Women",
          titleTop: 'Nail',
          titleBtm: 'Studio',
          imgUrl: 'assets/images/quick-link-images/salon/img03.webp'
        },
        {
          id: environment.production ? 'm0Oyqwi0PlI3nT5mAcmK' : '7hLC87HZvNMNiK5tm3yD',
          code: "Q016",
          newType: 16,
          subCategoryName: "Salon For Women",
          titleTop: 'Massage',
          titleBtm: '& Spa',
          imgUrl: 'assets/images/quick-link-images/salon/img04.webp'
        },
        {
          id: environment.production ? 'm0Oyqwi0PlI3nT5mAcmK' : '7hLC87HZvNMNiK5tm3yD',
          code: "Q016",
          newType: 16,
          subCategoryName: "Salon For Women",
          titleTop: 'Facial',
          titleBtm: 'Care',
          imgUrl: 'assets/images/quick-link-images/salon/img05.webp'
        },
        {
          id: environment.production ? 'm0Oyqwi0PlI3nT5mAcmK' : '7hLC87HZvNMNiK5tm3yD',
          code: "Q016",
          newType: 16,
          subCategoryName: "Salon For Women",
          titleTop: 'Threading',
          titleBtm: '& Waxing',
          imgUrl: 'assets/images/quick-link-images/salon/img06.webp'
        }
      ]
    },
  ];
  blueQuickLinkList: any[] = [
    {
      title: 'Cleaning Service',
      subTitle: 'Vetted & trained Zappers to get your cleaning needs sorted',
      items: [
        {
          id: environment.production ? 'rEMhqCyXcIGrcMIYI6fT' : 'T3URjFbydE3tlB4LYC9Q',
          code: "Q012",
          newType: 12,
          subCategoryName: "Maid Service",
          titleTop: 'Hourly Maid',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/cleaning/img01.webp'
        },
        {
          id: environment.production ? '0aX7csx75MLUA575xctw' : 'WmrxiUjxUGORT8iJ1jb9',
          code: "Q008",
          newType: 8,
          subCategoryName: "Deep Cleaning Service",
          titleTop: 'Deep Cleaning',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/cleaning/img02.webp'
        },
        {
          id: environment.production ? 'wklldrKKudZFZ1qyeTmS' : 'VeqRdZMJmPZgTILp3dVC',
          code: "Q001",
          newType: 3,
          subCategoryName: "AC Cleaning",
          titleTop: 'Air Conditioner',
          titleBtm: 'Cleaning',
          imgUrl: 'assets/images/quick-link-images/cleaning/img03.webp'
        },
        {
          id: environment.production ? '' : '',
          code: 'Q013',
          newType: 13,
          subCategoryName: "Carpet Cleaning",
          titleTop: 'Carpet Cleaning',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/cleaning/img04.webp'
        },
        {
          id: environment.production ? '0IxEndv4inQbLpQBrihu' : 'bxYsSzS2gDWD9D1RNmHP',
          code: "Q013",
          newType: 13,
          subCategoryName: "Furniture Cleaning",
          titleTop: 'Sofa Cleaning',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/cleaning/img05.webp'
        },
        {
          id: environment.production ? '' : '',
          code: 'Q012',
          newType: 12,
          subCategoryName: "Maid Service",
          titleTop: 'Home Cleaning',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/cleaning/img06.webp'
        },
        {
          id: environment.production ? '' : '',
          code: 'Q012',
          newType: 12,
          subCategoryName: "Maid Service",
          titleTop: 'Cleaning Company',
          titleBtm: 'in Dubai',
          imgUrl: 'assets/images/quick-link-images/cleaning/img07.webp'
        }
      ]
    },
    {
      title: 'Home Maintenance',
      subTitle: 'Relax and leave your worries to us',
      items: [
        {
          id: environment.production ? 'Dw6hz9hIixd44X4YyNbe' : 'AZgTJfVipSqklk5CllDT',
          code: "Q011",
          newType: 11,
          subCategoryName: "Pest Control",
          titleTop: 'Pest Control',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/home/img01.webp'
        },
        {
          id: environment.production ? 'qvCHdQxy7ghcgFXdvGFS' : 'GAV5uHV2VO4em1uKmi32',
          code: "Q017",
          newType: 17,
          subCategoryName: "Handyman Services",
          titleTop: 'Handyman',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/home/img02.webp'
        },
        {
          id: environment.production ? 'lWXnTkbrXR1yw32QMhd9' : 'NRy8fcHUZzSXmd6DyINH',
          code: "Q018",
          newType: 18,
          subCategoryName: "Plumber Services",
          titleTop: 'Plumbing',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/home/img03.webp'
        },
        {
          id: environment.production ? 'Fu3irfO4Nbjmy3y9rc0S' : '1ihajx7PfuMZ01wOV55I',
          code: "Q019",
          newType: 19,
          subCategoryName: "Electrician Services",
          titleTop: 'Electrician',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/home/img04.webp'
        },
        {
          id: environment.production ? '' : '',
          code: 'Q017',
          newType: 17,
          subCategoryName: "Carpenter Services",
          titleTop: 'Carpenter',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/home/img05.webp'
        },
        {
          id: environment.production ? 'wklldrKKudZFZ1qyeTmS' : 'VeqRdZMJmPZgTILp3dVC',
          code: "Q001",
          newType: 3,
          subCategoryName: "AC Cleaning Services",
          titleTop: 'AC Cleaning',
          titleBtm: 'Services',
          imgUrl: 'assets/images/quick-link-images/home/img06.webp'
        },
      ]
    },

    {
      title: 'Salon at Home',
      subTitle: 'Pamper yourself at home',
      isExtended: true,
      items: [
        {
          id: environment.production ? 'm0Oyqwi0PlI3nT5mAcmK' : '7hLC87HZvNMNiK5tm3yD',
          code: "Q016",
          newType: 16,
          subCategoryName: "Salon For Women",
          tag: 'Save More',
          titleTop: 'Salon',
          titleBtm: 'Packages',
          imgUrl: 'assets/images/quick-link-images/salon/img01.webp'
        },
        {
          id: environment.production ? 'm0Oyqwi0PlI3nT5mAcmK' : '7hLC87HZvNMNiK5tm3yD',
          code: "Q016",
          newType: 16,
          subCategoryName: "Salon For Women",
          titleTop: 'Hair',
          titleBtm: 'Studio',
          imgUrl: 'assets/images/quick-link-images/salon/img02.webp'
        },
        {
          id: environment.production ? 'm0Oyqwi0PlI3nT5mAcmK' : '7hLC87HZvNMNiK5tm3yD',
          code: "Q016",
          newType: 16,
          subCategoryName: "Salon For Women",
          titleTop: 'Nail',
          titleBtm: 'Studio',
          imgUrl: 'assets/images/quick-link-images/salon/img03.webp'
        },
        {
          id: environment.production ? 'm0Oyqwi0PlI3nT5mAcmK' : '7hLC87HZvNMNiK5tm3yD',
          code: "Q016",
          newType: 16,
          subCategoryName: "Salon For Women",
          titleTop: 'Massage',
          titleBtm: '& Spa',
          imgUrl: 'assets/images/quick-link-images/salon/img04.webp'
        },
        {
          id: environment.production ? 'm0Oyqwi0PlI3nT5mAcmK' : '7hLC87HZvNMNiK5tm3yD',
          code: "Q016",
          newType: 16,
          subCategoryName: "Salon For Women",
          titleTop: 'Facial',
          titleBtm: 'Care',
          imgUrl: 'assets/images/quick-link-images/salon/img05.webp'
        },
        {
          id: environment.production ? 'm0Oyqwi0PlI3nT5mAcmK' : '7hLC87HZvNMNiK5tm3yD',
          code: "Q016",
          newType: 16,
          subCategoryName: "Salon For Women",
          titleTop: 'Threading',
          titleBtm: '& Waxing',
          imgUrl: 'assets/images/quick-link-images/salon/img06.webp'
        }
      ]
    },
  ];

  constructor() { }

  getDefaultAddress(): Address {
    const _address = {
      id: 'DFLT0001',
      currentGeo: new firebase.default.firestore.GeoPoint(this.defaultLatLng?.lat, this.defaultLatLng?.lng),
      landmarkKey: 'Motor City',
      location: 'Dubai, UAE'
    };
    return _address as Address;
  }

  getQuikLinkList() {
    return this.quickLinkList;
  }

  getBlueQuikLinkList() {
    return this.blueQuickLinkList;
  }

  public getMaidServiceTypes(): string[] {
    return ['Part Time', 'Monthly Booking', 'Holiday Homes', 'Other'];
  }

  public getDefaultSources(): string[] {
    return ['Driver', 'Maid', 'Admin', 'Other'];
  }
}
