export const environment = {
  webVersion: 'YCWEB-' + require('../../package.json').version + '-dev',
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyD8dqvAo066InMsHXpXEpyE_Tx6LFA6ojA",
    authDomain: "yellochat-12b69.firebaseapp.com",
    databaseURL: "https://yellochat-12b69.firebaseio.com",
    projectId: "yellochat-12b69",
    storageBucket: "yellochat-12b69.appspot.com",
    messagingSenderId: "840111947673",
    appId: "1:840111947673:web:542bbe22004c5fc75d1d08",
    measurementId: "G-ZXYB89XM7G",
    vapidKey: "BDazMhupdZj7nXnVBeVPtP4SnvG0ZCaJSLwxjnw9YbSlWybY9U7kOJs0GxzwjrdVeuCrvpaOA3Gz6GcLD01M99Y"
  },
  paymentUrl: 'https://us-central1-yellochat-12b69.cloudfunctions.net/hyLoPayment',
  paymentStatusUrl: 'https://us-central1-yellochat-12b69.cloudfunctions.net/hyLoPaymentStatus',
  stripePaymentUrl: 'https://us-central1-yellochat-12b69.cloudfunctions.net/createPayment',
  stripeMakePaymentUrl: 'https://us-central1-yellochat-12b69.cloudfunctions.net/makePayment',
  stripe_pk: 'pk_test_51Jb1vIAwElwhZJ1COyxkPNdW4whNiJNQUNpZUTjmR3EUuAto1vgqXq6ZJ6g8xrf0suEYTysoJIcGz7Dhx2vTBtk300lvG9jUhe',
  baseUrl: 'https://dev.yellowzap.com',
  googleMapKey: 'AIzaSyCz2OEjrEDXhGtWBv-tXb4q0JCub24XuWs',
  mapGeocodeUrl: 'https://maps.googleapis.com/maps/api/geocode/json',
  contactYello: 'https://us-central1-yellochat-12b69.cloudfunctions.net/contactYello',
  enableAdminFeatures: true,
  showLandingPopup: false,
  discountUrl: 'https://us-central1-yellochat-12b69.cloudfunctions.net/discountCode',
  availableDiscountsUrl: 'https://us-central1-yellochat-12b69.cloudfunctions.net/availableDiscounts',
  gtmId: 'GTM-T3RXNKV',
  maidDocId: 'T3URjFbydE3tlB4LYC9Q',
  pixelID: '1856675134689100',
  clevertapId: 'TEST-585-9Z6-K76Z',
  generateReferralCodeUrl: 'https://us-central1-yellochat-12b69.cloudfunctions.net/generateReferralCode',
  recentBookingReviewUrl: 'https://us-central1-yellochat-12b69.cloudfunctions.net/fetchRecentBookingReview',
  staffAvailability: 'https://us-central1-yellochat-12b69.cloudfunctions.net/staffAvailability'
};
