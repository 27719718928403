<footer>
    <div class="container">
        <div class="yc-footer">
            <div>
                <p>Company</p>
                <ul>
                    <li><a routerLink="/dubai/services">Our Services</a></li>
                    <li>
                        <a *ngIf="aboutUsLink" onclick="focusAboutUs()">About us</a>
                        <a *ngIf="!aboutUsLink" (click)="onAboutUsClick()">About us</a>
                    </li>
                    <li><a href="https://blog.yellowzap.com/" target="_blank" rel="noopener noreferrer">Blogs</a></li>
                    <!-- <li><a>Press</a></li> -->
                </ul>
            </div>
            <div>
                <p>Partners</p>
                <ul>
                    <li><a routerLink="/partner-with-us">Become a Partner</a></li>
                    <li>
                        <a *ngIf="downloadLink" onclick="focusDownload()">Download App</a>
                        <a *ngIf="!downloadLink" (click)="onDownloadClick()">Download App</a>
                    </li>
                    <li><a routerLink="/faq">FAQ</a></li>
                    <!-- <li><a>Terms and Conditions</a></li> -->
                    <li><a routerLink="/partner-with-us">Contact Us</a></li>
                </ul>
            </div>
            <div>
                <p>Customers</p>
                <ul>
                    <!-- <li><a>Our Services</a></li> -->
                    <li>
                        <a *ngIf="promoLink" onclick="focusPromotion()">Promotions</a>
                        <a *ngIf="!promoLink" (click)="onPromotionClick()">Promotions</a>
                    </li>
                    <!-- <li><a>How it works</a></li> -->
                    <!-- <li><a>FAQs</a></li> -->
                    <li><a routerLink="/tnc">Terms & Conditions</a></li>
                    <li><a routerLink="/privacy-policy">Privacy policies</a></li>
                    <li><a routerLink="/contact-us">Contact Us</a></li>
                </ul>
            </div>
            <div class="d-none mobile-only">
                <p>Contact Us</p>
                <ul>
                    <li><a href="tel:+971 543995399"><strong>+971 543995399</strong></a></li>
                    <li><a href="mailto:zapcare@yellowzap.com"><strong>zapcare@yellowzap.com</strong></a>
                    </li>
                </ul>
            </div>
            <div>
                <div class="row">
                    <div class="col-12 d-none web-only">
                        <p>Contact Us</p>
                        <ul>
                            <li><a href="tel:+971 543995399"><strong>+971 543995399</strong></a></li>
                            <li><a href="mailto:zapcare@yellowzap.com"><strong>zapcare@yellowzap.com</strong></a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12">
                        <p>Follow us on</p>
                        <div class="follow-area">
                            <a href="https://www.facebook.com/yellochatdxb" target="_blank" rel="noopener noreferrer" class="
                                follow-area__facebook">
                                <span></span>
                                <span></span>
                            </a>
                            <a href="https://www.twitter.com/yellochatdxb/" target="_blank" rel="noopener noreferrer"
                                class="follow-area__twitter">
                                <span></span>
                                <span></span>
                            </a>
                            <a href="https://www.instagram.com/yellowzapers/" target="_blank" rel="noopener noreferrer"
                                class="follow-area__insta">
                                <span></span>
                                <span></span>
                            </a>
                            <a href="https://www.youtube.com/channel/UCUeQTJqUuQg2c9Y_HcnTyQw" target="_blank" rel="noopener noreferrer"
                                class="follow-area__youtube">
                                <span></span>
                                <span></span>
                            </a>
                            <!-- <a href="#" class="follow-area__lb">
                                <span></span>
                                <span></span>
                            </a>
                            <a href="#" class="follow-area__heart">
                                <span></span>
                                <span></span>
                            </a> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="yc-get-it">
                <p>Download App</p>
                <a target="_blank" 
                    rel="noopener noreferrer"
                    href="https://apps.apple.com/us/app/yellochat/id1513815109"><img
                        src="assets/images/appstore-md.png" alt="yellochat-appstore"></a>
                <a target="_blank"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.yellochat.prod&amp;hl=en&amp;gl=US"><img
                        src="assets/images/playstore-md.png" alt="yellochat-playstore"></a>
            </div>
        </div>
        <div class="yc-footer-break"></div>
        <div class="yc-footer-bottom">
            <p>Copyright &copy; 2023 <strong>Growgene Tech Solutions FZCO</strong> All rights reserved.</p>
        </div>
    </div>
</footer>
