<div class="confirm-booking-modal">
    <div class="confirm-booking-modal__header d-flex align-items-center justify-content-end">
        <a (click)="explore()"><img src="assets/images/icons/close-brwn.svg"></a>
    </div>

    <div class="scroll-body">

        <div class="modal-body container-fluid pt-0">
            <div class="confirm-booking-modal__body">
                <div class="top-sec">
                    <div class="d-flex justify-content-center">
                        <img *ngIf="!staffPicUri" src="assets/images/thumbs-up.webp" alt="">
                        <img *ngIf="staffPicUri" class="img-staff" src="{{staffPicUri}}" alt="">
                    </div>
                    <h4>{{headerStr}}</h4>
                    <p class="p1">Thank you for booking with us, we will send you the
                        best rated proffesionals from our vendors.</p>
                    <p class="p2">Your booking confirmation has been sent to</p>
                    <p class="p3">{{email}}<b *ngIf="email"> and </b>{{phone}}</p>
                    <h4 class="task-id">Task ID: {{taskId}}</h4>
                    <p class="p4">Check your task status anytime at</p>
                    <div class="d-flex justify-content-center">
                        <button class="task-btn" (click)="navigateToMyTasks()"><a>My Tasks</a></button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="isSecondaryUser && enableAdminFeatures && sharedPaymentLink" class="payment-link">
            <label class="ms-2 mb-1 copy-label">Payment Link (Task ID - {{taskId}})</label>
            <div class="row mb-2 d-flex justify-content-center">
                <div class="copy-link ms-1 me-2">
                    <input readonly type="text" class="copy-link-input" placeholder="Payment Link"
                        [value]="sharedPaymentLink">
                    <button class="copy-link-botton" type="submit" (click)="copyLink()">Copy</button>
                </div>
            </div>
        </div>

        <div class="confirm-booking-modal__banner d-flex justify-content-between">
            <div class="d-flex">
                <div class="d-flex align-items-end">
                    <div class="logo d-flex align-items-center justify-content-center">
                        <img src="assets/images/mockup-sm.webp" alt="">
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <div class="txt-div">
                        <h6>Download YellowZap app !</h6>
                        <p>Get real time updates and app exclusive deals</p>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-end">
                <button class="install-btn" (click)="downloadApp()"><a>Install Now</a></button>
            </div>
        </div>
        <div class="confirm-booking-modal__popular">
            <h6>Checkout our other popular services</h6>
            <div class="d-flex scroll-x">

                <div class="card h-100" (click)="quickBooking('/dubai/salon-women/Q016')">
                    <div class="img-div img-div--img1"><span>POPULAR</span></div>
                    <div class="card-body">
                        <h5 class="title-txt">Salon for Women</h5>
                        <p class="price-txt">Enjoy upto 15% discounts
                            <!-- <strong>AED 39</strong> -->
                        </p>
                        <a>Book Now</a>
                    </div>
                </div>

                <div class="card h-100" (click)="quickBooking('/dubai/pest-control/Q011')">
                    <div class="img-div img-div--img2"><span>POPULAR</span></div>
                    <div class="card-body">
                        <h5 class="title-txt">Pest Control</h5>
                        <p class="price-txt">Starting from <strong>AED 134</strong></p>
                        <a>Book Now</a>
                    </div>
                </div>

                <div class="card h-100" (click)="quickBooking('/dubai/express-pcr-test-at-home/Q010')">
                    <div class="img-div img-div--img3">
                        <span>POPULAR</span>
                    </div>
                    <div class="card-body">
                        <h5 class="title-txt">PCR Test at Home</h5>
                        <p class="price-txt">Starting from <strong>AED 89</strong></p>
                        <a>Book Now</a>
                    </div>
                </div>

                <div class="card h-100" (click)="quickBooking('/dubai/handyman-service/Q017')">
                    <div class="img-div img-div--img4">
                        <span>POPULAR</span>
                    </div>
                    <div class="card-body">
                        <h5 class="title-txt">Handyman Services</h5>
                        <p class="price-txt">Starting from <strong>AED 59</strong></p>
                        <a>Book Now</a>
                    </div>
                </div>

                <div class="card h-100" (click)="quickBooking('/dubai/furniture-cleaning/Q013')">
                    <div class="img-div img-div--img5">
                        <span>POPULAR</span>
                    </div>
                    <div class="card-body">
                        <h5 class="title-txt">Furniture Cleaning</h5>
                        <p class="price-txt">Starting from <strong>AED 29</strong></p>
                        <a>Book Now</a>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>