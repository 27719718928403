import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PriceBreakdown } from 'src/app/models/priceBreakdown.model';
import { QuickBookingService } from 'src/app/services/quick-booking.service';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss']
})
export class JobDetailComponent implements OnInit {

  @Input() data: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  docId: string = '';
  categoryType: number = 0;
  btnText: string = '';
  jobDetailsItemList: any[] = [];
  jobDetailPricingList: PriceBreakdown[] = [];
  otherInfoList: any[] = [];
  totalAmountVal: number = 0;
  showEditBtn: boolean = false;
  showRemoveBtn: boolean = false;
  editType: number = 1;
  allowProceedIndex: number = 0;
  adminEditDate: string = '';
  deliverySlot: any;
  hasEditedPrice: boolean = false;
  cutOfPrice:any;
  isDiscountIncluded:boolean=false;
  hasPackage:boolean=false;
  private ro: ResizeObserver | undefined;

  constructor(
    public activeModal: NgbActiveModal,
    private quickbookingServcice: QuickBookingService
  ) { }

  ngOnInit(): void {
    if (this.data) {
      const _data = this.data;
      this.docId = _data.docId;
      this.categoryType = _data.categoryType ?? 0;
      this.btnText = _data.btnText;
      this.jobDetailsItemList = _data.jobDetailsItemList;
      this.jobDetailPricingList = _data.jobDetailPricingList;
      this.otherInfoList = _data.otherInfoList;
      this.totalAmountVal = _data.totalAmountVal;
      this.showEditBtn = _data.showEditBtn;
      this.allowProceedIndex = _data.allowProceedIndex ?? 0;
      this.adminEditDate = _data.adminEditDate;
      this.deliverySlot = _data.deliverySlot;
      this.hasEditedPrice = _data.hasEditedPrice ?? false;
      this.editType = this.quickbookingServcice.getEditType(this.categoryType);
      this.showRemoveBtn = this.editType == 2 ? true : false;
      this.cutOfPrice=_data.cutOfPrice;
      this.isDiscountIncluded=_data.isDiscountIncluded;
      this.hasPackage=_data.hasPackage;
    }
    this.startObserve();
  }

  addZeros(value: number) {
    return value.toLocaleString("en", { useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  getPriceText(amount: number) {
    const val = 'AED ' + this.addZeros(amount);
    return val;
  }

  getAddOnCountText(addon: any): string {
    const unitCount = addon?.unitCount ?? 0;
    const countTxt = addon?.countText ?? '';
    if ((this.categoryType == 5 || this.categoryType == 9) && unitCount) {
      const str = ' (' + unitCount + ' ' + (unitCount == 1 ? countTxt : countTxt + 's') + ')';
      return str;
    }
    return '';
  }

  editItem(item: any, mainCatog: any) {
    const data = {
      docId: this.docId,
      action: 'EDIT',
      item: item,
      mainCatog: mainCatog
    }
    this.passBack(data);
  }

  removeItem(uid: string, item: any) {
    const data = {
      docId: this.docId,
      uid: uid,
      action: 'REMOVE',
      item: item
    }
    this.passBack(data);
  }

  proceed() {
    const data = {
      docId: this.docId,
      action: 'PROCEED',
      allowProceedIndex: this.allowProceedIndex
    };
    this.passBack(data);
  }

  passBack(data: any) {
    this.passEntry.emit(data);
    this.activeModal.close(data);
    this.close();
  }

  close() {
    this.activeModal.close();
    this.quickbookingServcice.closeModal();
  }

  startObserve() {
    this.ro = new ResizeObserver((entries) => {
      entries.forEach(entry => {
        const cr = entry.contentRect;
        if (cr.width > 767) {
          this.close();
        }
      })
    });
    this.ro.observe(document.body);
  }

  editOtherInfo(editIndex: number) {
    if (editIndex < 0 || editIndex >( this.hasPackage?2:1)) return;
    const data = {
      docId: this.docId,
      action: 'EDIT-OTHER',
      editIndex: editIndex
    };
    this.passBack(data);
  }

  setActiveClass(key: string) {
    const keys = ["longerHoursDiscount", "shortHoursMaterialDiscount","monthlyPackageDiscount","discount"];
    // this.isDiscountIncluded=keys.some(k=>k===key);
    return keys.includes(key);
  }

}
