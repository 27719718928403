import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { SubCategory } from '../models/subCategory.model';
import { StaffMember } from '../models/staffMember.model';
import { map, switchMap, toArray } from 'rxjs/operators';
import { Observable, combineLatest, from, of } from 'rxjs';
import { Requirement } from '../models/requirement.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StaffUnavailableComponent } from '../shared/components/staff-unavailable/staff-unavailable.component';
import { DataService } from './data.service';
import { StaffSelectComponent } from '../shared/components/staff-select/staff-select.component';
import { GeolocationService } from './geolocation.service';
import { Holiday } from '../models/holiday.model';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  private staffBookings: Requirement[] = [];
  private categoryBookings: Requirement[] = [];
  private categoryType?: number;
  private vendorHolidays: Holiday[] = [];
  localUid: string = '';

  constructor(
    private afs: AngularFirestore,
    private dataService: DataService,
    private modalService: NgbModal,
    private geoLocService: GeolocationService,
  ) {
    this.localUid = localStorage.getItem('uid') || '';
  }

  resetData() {
    this.staffBookings = [];
    this.categoryBookings = [];
    this.vendorHolidays = [];
  }

  async getStaffMembersCount(subCategoryId: string) {
    const querySnapshot = await this.afs.collection('staffMembers', (ref_1) => ref_1.where('role', '==', 'Staff')
      .where('assignServiceIds', 'array-contains', subCategoryId)
      .where('isActive', '==', true)
      .where('canDirectBroadcast', '==', true)
    )
      .get()
      .toPromise();
    return querySnapshot.size;
  }

  async getLocationStaffCount(subCategoryId: string) {
    const querySnapshot = await this.afs.collection('staffMembers', (ref_1) => ref_1.where('role', '==', 'Staff')
      .where('enroll', '==', true)
      .where('isActive', '==', true)
      .where('canDirectBroadcast', '==', true)
      .where('directAssignServiceIds', 'array-contains', subCategoryId)
    )
      .get()
      .toPromise();
    let staffList: StaffMember[] = [];
    let crntAddress = this.dataService.getCrntAddress();
    querySnapshot.docs.forEach((doc) => {
      const staff = doc.data() as StaffMember;
      if (this.geoLocService.isStaffAvailableLocation(crntAddress, staff)) {
        staffList.push(staff);
      }
    })
    return staffList.length;
  }

  getStaff(subCategory: SubCategory): Observable<StaffMember[]> {
    return this.afs
      .collection('staffMembers', (ref) =>
        ref
          .where('role', '==', 'Staff')
          .where('enroll', '==', true)
          .where('isActive', '==', true)
          .where('canDirectBroadcast', '==', true)
          .where('directAssignServiceIds', 'array-contains', subCategory.id)
          .orderBy('sort')
      )
      .get()
      .pipe(
        map((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as StaffMember)
        )
      );
  }

  getAllStaff(): Observable<StaffMember[]> {
    return this.afs
      .collection('staffMembers')
      .get()
      .pipe(
        map((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as StaffMember)
        )
      );
  }

  getCategoryBookings(type: number): Observable<Requirement[]> {
    if ((this.categoryBookings?.length > 0) && this.categoryType === type) {
      return new Observable<Requirement[]>((observer) => {
        observer.next(this.categoryBookings);
        observer.complete();
      });
    } else {
      return this.afs.
        collection('requirement', (ref) =>
          ref
            .where('status', 'not-in', ['delete', 'Draft'])
            .where('customerId', '==', this.localUid)
            .where('subCategoryDetails.type', '==', (type ?? 12))
        )
        .get()
        .pipe(
          map((querySnapshot) => {
            this.categoryBookings = querySnapshot.docs.map((doc) => doc.data() as Requirement);
            this.categoryType = type;
            return this.categoryBookings;
          }
          )
        );
    }
  }

  getStaffBookings(staffMembers: StaffMember[]): Observable<Requirement[]> {
    if (this.staffBookings.length > 0) {
      return of(this.staffBookings);
    }

    let futures: Observable<Requirement[]>[] = [];

    staffMembers.forEach((staff) => {
      if (!staff.docId) {
        return;
      }

      const query = this.afs
        .collection('requirement', (ref) =>
          ref
            .where('staffDetails.staffIds', 'array-contains', staff.docId)
            // TODO implement 'not-in' after updating firestore version to v9
            // .where('jobStatus', 'not-in', ['Job Completed', 'Job Cancelled'])
            .where('jobStatus', '!=', 'Job Completed')
            .where('bookingActive', '==', true)
        )
        .get()
        .pipe(
          map((value) => value.docs.map((e) => e.data() as Requirement)),
          switchMap((bookingModels) => from(bookingModels)),
          toArray()
        );
      futures.push(query);
    });

    return combineLatest(futures).pipe(
      map((results) =>
        results.reduce((acc, current) => [...acc, ...current], []))
    );
  }

  getVendorHolidays(staffMembers: StaffMember[], subCategory: SubCategory): Observable<Holiday[]> {
    if (this.vendorHolidays.length > 0) {
      return of(this.vendorHolidays);
    }
    let futures: Observable<Holiday[]>[] = [];

    staffMembers.forEach((staff) => {
      if (!staff?.vendorId || !subCategory?.id) {
        return;
      }
      const query = this.afs
        .collection('holidays', (ref) =>
          ref
            .where('vendors', 'array-contains', staff.vendorId)
          // .where('services', 'array-contains', subCategory.id)
        )
        .get()
        .pipe(
          map((value) => value.docs.map((e) => e.data() as Holiday)),
          switchMap((holidays) => from(holidays)),
          toArray()
        );
      futures.push(query);
    });

    return combineLatest(futures).pipe(
      map((results) => {
        const holidays = results.reduce((acc, current) => [...acc, ...current], []);
        this.vendorHolidays = holidays;
        return holidays;
      })
    );
  }

  isAnyStaffAvailableSingleStaff(
    state: { staffMembers: StaffMember[], selectSubType: any },
    allJobs: Requirement[],
    day: Date | null,
    time: Date | null,
    jobMinutes: number | null
  ): boolean {
    if (!day || !time || state.staffMembers.length === 0) return true;

    for (let i = 0; i < allJobs.length; i++) {
      if (this.checkBookingStillOnGoingNew(state, allJobs[i], day, time)) {
        return false;
      }
    }

    return this.isStaffAvailable(state.staffMembers[0], day, time, jobMinutes!);
  }

  checkBookingStillOnGoingNew(state: { staffMembers: StaffMember[], selectSubType: any }, ongoingBooking: Requirement | undefined, day: Date, time: Date): boolean {
    const now = new Date();
    let currentJobStartDate = new Date(day);
    currentJobStartDate.setHours(time.getHours(), time.getMinutes());

    let currentJobEndDate = new Date(currentJobStartDate);
    currentJobEndDate.setTime(
      currentJobEndDate.getTime() + (state.selectSubType?.minutes || 180) * 60 * 1000
    );

    if (ongoingBooking) {
      let ongoingJobStartDate = new Date(ongoingBooking.deliveryDay!.dateTime!);
      const timeGap: any = this.getBeforeAfterGaps(ongoingBooking, state?.staffMembers[0]);
      ongoingJobStartDate.setHours(
        new Date(ongoingBooking.deliverySlot!.time).getHours(),
        new Date(ongoingBooking.deliverySlot!.time).getMinutes(), 0, 0
      );
      if (timeGap[0]) {
        ongoingJobStartDate.setMinutes(ongoingJobStartDate?.getMinutes() - timeGap[0]);
      }
      const ongoingJobEndDate = new Date(ongoingJobStartDate);
      ongoingJobEndDate.setTime(ongoingJobEndDate.getTime() + ((this._getJobDuration(ongoingBooking) || 180) * 60 * 1000));
      if (timeGap[1]) {
        ongoingJobEndDate.setMinutes(ongoingJobEndDate?.getMinutes() + timeGap[1]);
      }

      if (ongoingJobEndDate.getTime() < now.getTime()) {
        return false;
      } else if (
        currentJobEndDate.getTime() < ongoingJobStartDate.getTime() ||
        currentJobStartDate.getTime() > ongoingJobEndDate.getTime()
      ) {
        return false;
      }
    }
    return true;
  }

  private getBeforeAfterGaps(ongoingBooking?: any, staff?: StaffMember): [number, number] {
    if (!staff || !ongoingBooking?.gapDetails || !ongoingBooking.bookingId || !staff.docId) {
      return [0, 0];
    }
    const before = ongoingBooking.gapDetails.beforeGapDuration || 0;
    const after = ongoingBooking.gapDetails.afterGapDuration || 0;
    return [before, after];
  }

  _getJobDuration(bookingModel?: Requirement): number {
    if (bookingModel?.items?.length && bookingModel.items[0].minutes !== null) {
      return bookingModel.items[0].minutes;
    }
    return 180;
  }

  /* oldisStaffAvailable(model: StaffMember | null | undefined, day: Date, time: Date): boolean {
    const jobDate = new Date(day.getFullYear(), day.getMonth(), day.getDate(), time.getHours(), time.getMinutes());
    const _jobDate = new Date(jobDate.getFullYear(), jobDate.getMonth(), jobDate.getDate());
    const holidays: Date[] = (model?.holidays || []).map((e: { nanoseconds: number, seconds: number }) => {
      const dateTime = this.getDateFromTimestamp(e);
      return new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate());
    });

    if (holidays.some((holiday) => holiday.getTime() === _jobDate.getTime())) {
      return false;
    }

    if (model && model.leaves) {
      for (let i = 0; i < (model?.leaves?.length || 0); i++) {
        const data = model!.leaves![i];

        if (data.status !== 'approved') continue;

        const _from = this.getDateFromTimestamp(data?.fromDate);
        const from = new Date(_from.getFullYear(), _from.getMonth(), _from.getDate());

        if (_jobDate.getTime() === from.getTime()) {
          if (data.duration === 'Single') {
            return false;
          } else {
            const _to = this.getDateFromTimestamp(data?.toDate);
            const to = new Date(_to.getFullYear(), _to.getMonth(), _to.getDate());

            if (to.getTime() >= _jobDate.getTime() && from.getTime() <= _jobDate.getTime()) {
              return false;
            }
          }
        }
      }
    }

    return (model?.weeklyOff?.days?.includes(jobDate.getDay()) || false) === false; // || model?.weeklyOff?.status === 'pending';
  } */

  isStaffAvailable(model: StaffMember | null | undefined, day: Date, time: Date, jobMinutes: number): boolean {
    const jobDate = new Date(day.getFullYear(), day.getMonth(), day.getDate(), time.getHours(), time.getMinutes());

    const holidays: Date[] = (model?.holidays || []).map((e: { nanoseconds: number, seconds: number }) => {
      const dateTime = this.getDateFromTimestamp(e);
      return new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate(), dateTime.getHours(), dateTime.getMinutes());
    });

    if (holidays.some((date) => date.getTime() === jobDate.getTime())) {
      return false;
    }

    const slotDisable: Date[] = (model?.slotDisable || []).map((e: { nanoseconds: number, seconds: number }) => {
      const dateTime = this.getDateFromTimestamp(e);
      return new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate(), dateTime.getHours(), dateTime.getMinutes());
    });

    if (slotDisable.some(date => date.getTime() === jobDate.getTime()) || !this.isSlotAvailable(jobDate, slotDisable, jobMinutes)) {
      return false;
    }

    const _jobDate = new Date(jobDate.getFullYear(), jobDate.getMonth(), jobDate.getDate());

    if (model && model.leaves) {
      for (let i = 0; i < (model?.leaves?.length || 0); i++) {
        const data = model!.leaves![i];

        if (data.status !== 'approved') continue;
        // const _from = data?.fromDate?.toDate();
        // const _from = new Date(data?.fromDate?.seconds * 1000);
        const _from = this.getDateFromTimestamp(data?.fromDate);
        const from = new Date(_from!.getFullYear(), _from!.getMonth(), _from!.getDate());
        if (_jobDate.getTime() === from.getTime()) {
          if (data.duration === 'Single') {
            return false;
          } else {
            // const _to = data.toDate!.toDate();
            // const _to = new Date(data?.toDate?.seconds * 1000);
            const _to = this.getDateFromTimestamp(data?.toDate);
            const to = new Date(_to.getFullYear(), _to.getMonth(), _to.getDate());
            if (to.getTime() >= _jobDate.getTime() && from.getTime() <= _jobDate.getTime()) {
              return false;
            }
          }
        }
      }
    }

    return (model?.weeklyOff?.days?.includes(jobDate.getDay()) || false) === false; // || model?.weeklyOff?.status == 'pending';
  }

  private isSlotAvailable(jobDate: Date, slotDisable: Date[], minutes: number): boolean {
    if (slotDisable.length === 0) return true;
    slotDisable.sort((a, b) => a.getTime() - b.getTime());
    const disableStart = slotDisable[0];
    const disableEnd = slotDisable[slotDisable.length - 1];
    const jobDurationMinutes = minutes ?? 0;
    const jobDuration = jobDurationMinutes * 60 * 1000;
    const jobEnd = new Date(jobDate.getTime() + jobDuration);

    if (jobDate < disableEnd && jobEnd > disableStart) {
      return false;
    }
    return true;
  }

  isJobDateWithinLeaveDate(jobDate: number, leaveDate: number): boolean {
    const jobDateTime = new Date(jobDate);
    const leaveDateTime = new Date(leaveDate);

    return (
      jobDateTime.getFullYear() === leaveDateTime.getFullYear() &&
      jobDateTime.getMonth() === leaveDateTime.getMonth() &&
      jobDateTime.getDate() === leaveDateTime.getDate()
    );
  }

  getDateFromTimestamp(timeStamp: any): Date {
    const { nanoseconds = 0, seconds = 0 } = timeStamp ?? { nanoseconds: 0, seconds: 0 };
    if (!nanoseconds && !seconds) return new Date();
    const date = new Date((seconds ?? 0) * 1000);
    date.setMilliseconds(date.getMilliseconds() + (nanoseconds ?? 0) / 1e6);
    return date;
  }

  getStaffs(filterStaffs: StaffMember[], requirement?: Requirement[]): [StaffMember[], StaffMember[]] {
    const bookedAgain: StaffMember[] = [];
    const nonBooked: StaffMember[] = [];

    if (!requirement || requirement.length === 0) {
      return [bookedAgain, filterStaffs];
    }

    for (let j = 0; j < filterStaffs.length; j++) {
      let isBooked = false;
      for (let i = 0; i < requirement.length; i++) {
        if (!requirement[i]?.staffDetails?.staffIds) {
          continue;
        }
        const bookingModel = requirement[i];
        if (bookingModel?.staffDetails?.staffIds?.includes(filterStaffs[j]?.docId)) {
          isBooked = true;
          bookedAgain.push(filterStaffs[j]);
          break;
        }
      }
      if (!isBooked) {
        nonBooked.push(filterStaffs[j]);
      }
    }
    return [bookedAgain, nonBooked];
  }

  openMaidSelectModal(categoryData: SubCategory, staffMembers?: StaffMember[]) {
    const modalRef = this.modalService.open(StaffSelectComponent, {
      backdrop: 'static',
      centered: true,
      keyboard: false
    });
    modalRef.componentInstance.subCategory = categoryData;
    modalRef.componentInstance.staffs = staffMembers;
    modalRef.result.then((res) => {
      if (res?.action === "AUTO-ASSIGN") {
        this.dataService.changeSelectedStaffData(false);
      }
      if (res?.action === "STAFF" && res?.selectedStaff?.length > 0) {
        this.dataService.changeSelectedStaffData(res.selectedStaff);
      }
    });
  }

  openStaffUnavailableModal(staffMembers: StaffMember[], categoryData: SubCategory, jobDateTime: Date) {
    const modalRef = this.modalService.open(StaffUnavailableComponent, {
      backdrop: 'static',
      centered: true,
      keyboard: false
    });
    modalRef.componentInstance.staffMembers = staffMembers;
    modalRef.componentInstance.jobDateTime = jobDateTime;
    modalRef.result.then((res) => {
      if (res.action === "AUTO") {
        this.dataService.changeSelectedStaffData(false);
      } else if (res.action === "STAFF") {
        this.openMaidSelectModal(categoryData);
      } else if (res.action === "TIME") {
        modalRef.close();
      }
    });
  }

}
