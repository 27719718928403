import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { PixelService } from 'ngx-pixel';
import { environment } from 'src/environments/environment';
import clevertap from 'clevertap-web-sdk';
import { User } from '../models/user.model';
import { SubCategory } from '../models/subCategory.model';

@Injectable({
  providedIn: 'root'
})
export class EventLogService {

  constructor(
    private pixel: PixelService,
    private analytics: AngularFireAnalytics
  ) { }

  otpInitiated(mobileNo: string, countryCode: string) {
    // OTP Requested
    const params = {
      Platform: 'WEB',
      MobileNo: mobileNo ?? 'unknown',
      CountryCode: countryCode ?? 'unknown'
    };
    if (environment.production)
      this.pixel.trackCustom('OTPInitiated', params);
    this.analytics.logEvent('OTPInitiated', params);
    clevertap.event.push('OTPInitiated', params);
  }

  signup(uid: string, user: User) {
    // OTP Verfications
    const params = {
      Platform: 'WEB',
      Identity: uid ?? 'unknown',
      "Phone": user?.phone ?? 'unknown',
      ...(user?.displayName && {
        "Name": user?.displayName,
      }),
      ...(user?.email && {
        "Email": user?.email,
      }),
    }
    if (environment.production)
      this.pixel.trackCustom('Signup', params);
    this.analytics.logEvent('Signup', params);
    this.clevertapOnUserLogin(uid, user?.phone, user?.displayName, user?.email);
  }

  clickedOnService(code: string, categoryType: number, name: string, id: string) {
    // Clicked on a Quickbooking Service
    const uid = localStorage.getItem('uid');
    const params = {
      Platform: 'WEB',
      Code: code ?? 'unknown',
      CategoryType: categoryType ?? 'unknown',
      BookingType: 'Quick Booking',
      ServiceName: name,
      ServiceId: id,
      ...(uid && {
        "Identity": uid,
      }),
    }
    if (environment.production)
      this.pixel.trackCustom('ClickedOnService', params);
    this.analytics.logEvent('ClickedOnService', params);
    clevertap.event.push('ClickedService', params);
  }

  addedToCart(categoryType: number, isLoggedIn: boolean, categoryData?: SubCategory) {
    // Clicked on Proceed to Book
    const uid = localStorage.getItem('uid');
    const params = {
      Platform: 'WEB',
      CategoryType: categoryType ?? 'unknown',
      IsLoggedIn: isLoggedIn,
      ServiceName: categoryData?.subCategoryName ?? 'unknown',
      ServiceId: categoryData?.id ?? 'unknown',
      Code: categoryData?.code ?? 'unknown',
      ...(uid && {
        "Identity": uid,
      }),
    }
    if (environment.production)
      this.pixel.trackCustom('AddedToCart', params);
    this.analytics.logEvent('AddedToCart', params);
    clevertap.event.push('AddedToCart', params);
  }

  addedAddress(location: string) {
    // Added address
    const uid = localStorage.getItem('uid');
    const params = {
      Platform: 'WEB',
      Location: location ?? 'unknown',
      ...(uid && {
        "Identity": uid,
      }),
    }
    if (environment.production)
      this.pixel.trackCustom('AddedAddress', params);
    this.analytics.logEvent('AddedAddress', params);
    clevertap.event.push('AddedAddress', params);
  }

  addedTime(categoryData?: SubCategory, total?: string) {
    // Click on Select Time
    const uid = localStorage.getItem('uid');
    const params = {
      Platform: 'WEB',
      ...(uid && {
        "Identity": uid,
      }),
      ServiceName: categoryData?.subCategoryName ?? 'unknown',
      CategoryType: categoryData?.newType ?? 'unknown',
      ServiceId: categoryData?.id ?? 'unknown',
      Code: categoryData?.code ?? 'unknown',
      TotalPrice: total ?? 'unknown', // 0.00
      // 'BookingTime': bookingData['bookingTime'], // MMM dd, yyyy - hh:mm a
    }
    if (environment.production)
      this.pixel.trackCustom('AddedTime', params);
    this.analytics.logEvent('AddedTime', params);
    clevertap.event.push('AddedTime', params);
  }

  addedPaymentInfo(subCategory: any, code: string, bookingDate?: string, total?: string) {
    // Click on Select Payment Method
    const uid = localStorage.getItem('uid');
    const params = {
      Platform: 'WEB',
      ...(uid && {
        "Identity": uid,
      }),
      ServiceName: subCategory?.subCategoryName ?? 'unknown',
      CategoryType: subCategory?.newType ?? 'unknown',
      ServiceId: subCategory?.id ?? 'unknown',
      Code: code ?? 'unknown',
      BookingTime: bookingDate ?? 'unknown', // MMM dd, yyyy - hh:mm a
      TotalPrice: total ?? 'unknown', // 0.00
    }
    if (environment.production)
      this.pixel.trackCustom('AddedPaymentInfo', params);
    this.analytics.logEvent('AddedPaymentInfo', params);
    clevertap.event.push('AddedPaymentInfo', params);
  }

  initiatedCheckout(amount: number, subCategory: any, paymentType: string, code: string) {
    // Click on Make Payment/Confirm Booking
    const uid = localStorage.getItem('uid');
    const params = {
      Platform: 'WEB',
      TotalPrice: amount ?? 'unknown', // chnage type to string(formatted 128.00)
      ...(uid && {
        "Identity": uid,
      }),
      ServiceName: subCategory?.subCategoryName ?? 'unknown',
      CategoryType: subCategory?.newType ?? 'unknown',
      ServiceId: subCategory?.id ?? 'unknown',
      Code: code ?? 'unknown',
      PaymentType: paymentType ?? 'unknown'
    }
    if (environment.production)
      this.pixel.trackCustom('InitiatedCheckout', params);
    this.analytics.logEvent('InitiatedCheckout', params);
    clevertap.event.push('InitiatedCheckout', params);
  }

  purchased(bookingId: string, serviceName: string, paymentType: string, bookingDate: string, total: string) {
    // Booking success
    const uid = localStorage.getItem('uid');
    const params = {
      Platform: 'WEB',
      ServiceName: serviceName ?? 'unknown',
      PaymentType: paymentType?.toLowerCase(),
      BookingId: bookingId ?? 'unknown',
      BookingTime: bookingDate, // MMM dd, yyyy - hh:mm a
      TotalPrice: total, // 0.00
      ...(uid && {
        "Identity": uid,
      }),
    }
    if (environment.production)
      this.pixel.trackCustom('Purchased', params);
    this.analytics.logEvent('Purchased', params);
    clevertap.event.push('Purchased', params);
  }

  clevertapSignup(uid: string, user: User) {
    // OTP Verfication (new user signup)
    const params = {
      Platform: 'WEB',
      Identity: uid ?? 'unknown',
      "Phone": user?.phone ?? 'unknown',
      ...(user?.displayName && {
        "Name": user?.displayName,
      }),
      ...(user?.email && {
        "Email": user?.email,
      }),
    }
    clevertap.event.push('Signup', params);
  }

  clevertapOnUserLogin(uid: string, phone: string, displayName?: string, email?: string) {
    if (!uid) return;
    clevertap.onUserLogin.push({
      "Site": {
        "Platform": 'WEB',
        "Identity": uid,
        "Phone": phone,
        ...(displayName && {
          "Name": displayName,
        }),
        ...(email && {
          "Email": email,
        }),
      }
    });
  }

}
