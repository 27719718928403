import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { QuickBookingService } from 'src/app/services/quick-booking.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-activate-referral',
  templateUrl: './activate-referral.component.html',
  styleUrls: ['./activate-referral.component.scss']
})
export class ActivateReferralComponent implements OnInit {

  referralCode: string = '';
  showLoader: boolean = false;
  localUid: string = '';
  errorTxt: string = '';

  @Input() refData: any;
  @Input() reopenAvailablePromo: boolean = false;
  @Input() activeDocId?: string;

  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private quickBookingService: QuickBookingService,
    private toastr: ToastrService,
    private snackbarService: SnackbarService,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.localUid = localStorage.getItem('uid') ?? '';
  }

  close() {
    this.activeModal.close();
  }

  activateReferral() {
    if (this.referralCode?.length < 4) {
      this.errorTxt = 'Please enter a valid referral code!';
      return;
    }
    this.showLoader = true;
    this.errorTxt = '';
    const req = {
      "userId": this.localUid ?? '',
      "referral": true,
      "code": this.referralCode ?? ''
    }

    this.apiService.activateReferralCode(req).subscribe(
      async res => {
        this.setActiveReferral(res);
      },
      error => {
        this.showLoader = false;
        if (error?.error?.message) {
          this.errorTxt = error?.error?.message ?? '';
        }
        else {
          this.toastr.clear();
          this.toastr.error(
            'Oops! Something went wrong.', 'Something went wrong!',
            { positionClass: 'toast-bottom-right' },
          );
          throw error;
        }
      }
    )
  }

  async setActiveReferral(res: any) {
    if (res?.status === 'success') {
      const crntDocId = this.activeDocId ?? environment.maidDocId;
      const updatedRes = await this.quickBookingService.getUpdatedAvailablePromos(crntDocId, true);
      this.showSnackbar(res);
      this.dataService.changeAvailableDiscounts({ docId: crntDocId, res: updatedRes, uid: this.localUid });
      this.close();
      if (this.reopenAvailablePromo) {
        this.dataService.changeCrntOpenPromo(true);
      }
    }
  }

  showSnackbar(res: any) {
    const data = {
      icon: '🥳',
      text: res?.message ?? 'Your referral has been successfully activated.'
    }
    this.snackbarService.showSnackBar(data);
  }

  onChange(value: string) {
    if (!this.errorTxt) return;
    if (value?.length >= 3) {
      this.errorTxt = '';
    }
  }

}
