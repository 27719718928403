export function getMobileNumber(countryCode: string, phoneNo: string) {
  const codeDigits = countryCode.replace('+', '');
  let cleanPhoneNo = phoneNo.replace(/^\+/, '');

  if (cleanPhoneNo.startsWith(codeDigits)) {
    cleanPhoneNo = cleanPhoneNo.substring(codeDigits.length);
  } else if (cleanPhoneNo.startsWith('0')) {
    cleanPhoneNo = cleanPhoneNo.substring(1);
  }

  if (countryCode === '+94') {
    cleanPhoneNo = '7' + cleanPhoneNo.slice(-8);
  } else if (countryCode === '+61') {
    cleanPhoneNo = cleanPhoneNo.slice(-9);
  } else if (countryCode === '+971') {
    cleanPhoneNo = cleanPhoneNo.slice(-9);
  } else {
    cleanPhoneNo = cleanPhoneNo.slice(-10);
  }

  return countryCode + cleanPhoneNo;
}



//Old Logic
// getMobileNumber(countryCode: string, phoneNo: string) {
//   if (countryCode.includes('+971')) {
//     // return countryCode + phoneNo.substring(phoneNo.length - 9);
//     return countryCode + phoneNo.replace('+', '').replace('971', '');
//   }
//   else if (countryCode.includes('+94')) {
//     return countryCode + '7' + phoneNo.substring(phoneNo.length - 8);
//   }
//   else if (countryCode.includes('+61')) {
//     return countryCode + phoneNo.substring(phoneNo.length - 9);
//   }
//   else {
//     return countryCode + phoneNo.substring(phoneNo.length - 10);
//   }
// }
