import { Component, Input, OnInit } from '@angular/core';
import { FcmPayload } from 'src/app/models/fcmPayload.model';
import { FcmService } from 'src/app/services/fcm.service';

@Component({
  selector: 'app-notification-wrapper',
  templateUrl: './notification-wrapper.component.html',
  styleUrls: ['./notification-wrapper.component.scss']
})
export class NotificationWrapperComponent implements OnInit {

  title: string = 'YellowZap Notification!';
  body: string = '';
  payload?: FcmPayload;
  type: number = 0; // 0 = request permission; 1 = notification

  constructor(private fcmService: FcmService) { }

  ngOnInit(): void {
    this.fcmService.message$.subscribe((data) => {
      this.type = data?.type ?? 0;
      this.payload = data?.payload;
      this.setNotificationData(data?.payload);
    });
  }

  setNotificationData(payload: FcmPayload) {
    this.title = payload?.notification?.title ?? '';
    this.body = payload?.notification?.body ?? '';
  }

  close() {
    this.fcmService.hideNotification();
  }

}
