<div class="activate-referral-modal">
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
    <div class="activate-referral-modal__header d-flex align-items-center justify-content-end" ngbAutofocus>
        <a (click)="close()"><img src="assets/images/icons/close-brwn.svg"></a>
    </div>
    <div class="img-sec d-flex align-items-center justify-content-center">
        <img src="assets/images/gift-img.webp" alt="">
    </div>
    <div class="activate-referral-modal__body">
        <h4 class="mb-1">{{refData.title}}</h4>
        <h4>{{refData.subTitle2}}</h4>

        <input type="text" placeholder="Enter Referral Code" [ngClass]="{'input-error': errorTxt}" (ngModelChange)="onChange($event)"
            oninput="let p=this.selectionStart; this.value=this.value.toUpperCase(); this.setSelectionRange(p, p);"
            [(ngModel)]="referralCode" #ctrl="ngModel" required>
        <span *ngIf="errorTxt" class="error-txt"><img src="/assets/images/icons/info-circle-red.svg"
                alt="">{{errorTxt}}</span>
        <button (click)="activateReferral()" [disabled]="showLoader">Activate Voucher
            <div class="loading-spinner" *ngIf="showLoader"></div>
        </button>
    </div>
</div>