import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction, DocumentData, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Requirement } from '../models/requirement.model';
import { TaskDetailsComponent } from '../modules/my-task/task-details/task-details.component';

@Injectable({
  providedIn: 'root'
})
export class MytaskService implements OnDestroy {

  private _activeModal!: NgbModalRef;
  subscriptions: Subscription[] = [];
  myTasks: Requirement[] = [];
  lastMyTaskDoc?: QueryDocumentSnapshot<DocumentData>;
  localUid: string = '';
  private activeTaskTabSource = new BehaviorSubject(0);

  private requirementCollection: AngularFirestoreCollection<Requirement>;
  private requirementSubscription?: Subscription;
  tasks$: Observable<DocumentChangeAction<Requirement>[]>;
  private tasksSubject: BehaviorSubject<DocumentChangeAction<Requirement>[]>;

  crntActiveTaskTab = this.activeTaskTabSource.asObservable();

  constructor(private modalService: NgbModal, private afs: AngularFirestore) {
    this.localUid = localStorage.getItem('uid') || '';
    this.tasksSubject = new BehaviorSubject<DocumentChangeAction<Requirement>[]>([]);
    this.tasks$ = this.tasksSubject.asObservable();
    this.requirementCollection = this.afs.collection<Requirement>('requirement', (ref) =>
      ref
        .where('status', 'not-in', ['delete', 'Draft'])
        .where('customerId', '==', this.localUid)
    );
    this.subscribeToTasks();
  }

  private subscribeToTasks(): void {
    this.requirementSubscription = this.requirementCollection.snapshotChanges().subscribe((changes) => {
      if (changes) {
        this.tasksSubject.next(changes);
      }
    });
  }

  public reinitializeTasks(): void {
    this.localUid = localStorage.getItem('uid') || '';

    if (this.localUid && (this.localUid?.length > 0)) {
      this.requirementSubscription?.unsubscribe();
      this.requirementCollection = this.afs.collection<Requirement>('requirement', (ref) =>
        ref
          .where('status', 'not-in', ['delete', 'Draft'])
          .where('customerId', '==', this.localUid)
      );
      this.subscribeToTasks();
    }
  }


  public async getTaskInfo(bookingId: string) {
    if (bookingId) {
      const ref = this.afs.collection<Requirement>('requirement', (ref) => ref.where('status', 'not-in', ['delete', 'Draft'])).doc(bookingId);
      const doc = await ref.ref.get();
      if (doc.exists) {
        let data = doc.data() as any;
        data.docId=doc.id;
        return data;
      }
    }
    return null;
  }

  ngOnDestroy(): void {
    this.requirementSubscription?.unsubscribe();
  }

  /**
   * open my task details modal
   * @return void
   */
  openModal(data?: { currentUI?: string }, taskData?: Requirement) {
    this._activeModal = this.modalService.open(TaskDetailsComponent, {
      windowClass: 'mytask-modal',
      backdrop: 'static',
      keyboard: false,
    });
    this._activeModal.componentInstance.task = taskData;

    if (data && data.currentUI) {
      this._activeModal.componentInstance.currentUI = data.currentUI;
    }
  }

  /**
   * open my task details modal
   * @return void
   */
  /* openVendorModal(data?: { currentUI?: string }) {
    this._activeModal = this.modalService.open(VendorsListComponent, {
      windowClass: 'mytask-modal',
      backdrop: 'static',
      keyboard: false,
    });

    if (data && data.currentUI) {
      this._activeModal.componentInstance.currentUI = data.currentUI;
    }
  } */

  /**
   * close my task details modal
   * @return void
   */
  closeModal() {
    this._activeModal.close();
  }

  changeActiveTaskTab(index: number) {
    this.activeTaskTabSource.next(index);
  }
}
