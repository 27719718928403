<div id="notification-wrapper">
    <!-- <div *ngIf="type === 0" class="request">
        <div class="close-btn-row">
            <a (click)="close()"><img src="assets/images/icons/close-brwn.svg"></a>
        </div>
        <div class="body-row">
            <div class="left-col">
                <img src="assets/images/icons/yc-logo.webp" alt="">
            </div>
            <div class="right-col">
                <h4>Subscribe to notifications</h4>
                <p>Get booking updates, latest discounts, promotions and more!</p>
                <div class="btn-row">
                    <button class="btn btn__allow">Allow</button>
                    <button class="btn">Don't allow</button>
                </div>
            </div>
        </div>
    </div> -->
    <div *ngIf="type === 1" class="notification">
        <div class="d-flex align-items-center justify-content-between">
            <div class="left-sec d-flex align-items-center">
                <img src="assets/images/icons/notification.webp" alt="">
                <div>
                    <p class="title">{{title}}</p>
                </div>
            </div>
            <div class="right-sec">
                <a (click)="close()"><img src="assets/images/icons/close-brwn.svg"></a>
            </div>
        </div>
        <div *ngIf="body" class="body-sec">
            <p class="body">{{body}}</p>
        </div>
    </div>
</div>