import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-skip-location',
  templateUrl: './skip-location.component.html',
  styleUrls: ['./skip-location.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class SkipLocationComponent implements OnInit {

  @Output() onAction = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  public onAction$(action: string) {
    this.onAction.emit(action);
  }

}
