<div class="whats-include-modal">
    <div class="modal-body container-fluid">
        <div class="scroll-sec">
            <img src="assets/images/icons/close-sm.png" alt="close.png" (click)="activeModal.close()">
            <div class="info mt-1">
                <ng-container *ngFor="let d of data; let i=index;">
                    <label *ngIf="d?.main" class="mb-2">{{d.heading}}</label>
                    <label *ngIf="!d?.main && d?.isTitle" class="sub-title">{{d.heading}}</label>
                    <div *ngIf="!d.isList">
                        <p *ngIf="d?.isTitle">{{d.description}}</p>
                        <p *ngIf="!d?.isTitle"><b>{{d.heading}}</b> - {{d.description}}</p>
                    </div>
                    <ul *ngIf="d.isList">
                        <li *ngFor="let item of d.items">{{item}}</li>
                    </ul>
                </ng-container>
            </div>
        </div>
    </div>
</div>