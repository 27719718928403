import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ReassignPreferences } from 'src/app/models/monthly-booking.model';
import { Requirement } from 'src/app/models/requirement.model';
import { StaffMember } from 'src/app/models/staffMember.model';
import { SubCategory } from 'src/app/models/subCategory.model';
import { DataService } from 'src/app/services/data.service';
import { GeolocationService } from 'src/app/services/geolocation.service';
import { StaffService } from 'src/app/services/staff.service';

@Component({
  selector: 'app-staff-select',
  templateUrl: './staff-select.component.html',
  styleUrls: ['./staff-select.component.scss']
})
export class StaffSelectComponent implements OnInit, OnDestroy {

  staffMembers: StaffMember[] = [];
  searchResults: StaffMember[] = [];
  private subscription?: Subscription;
  searchTerm: string = '';
  requiredStaffCount: number = 1;
  selectedStaff: StaffMember[] = [];
  isStaffChecked: boolean = false;
  categoryBookings: Requirement[] = [];
  bookAgainStaff: StaffMember[] = [];
  nonBookedStaff: StaffMember[] = [];
  showSearchResults: boolean = false;

  @Input() subCategory?: SubCategory;
  @Input() preference?: ReassignPreferences;
  @Input() staffIds?: string[] = [];
  @Input() staffs?: StaffMember[] = [];
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    private staffService: StaffService,
    private dataService: DataService,
    private geoLocService: GeolocationService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.loadStaffMembers();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  loadStaffMembers() {
    if (this.subCategory?.id) {
      if (this.staffs?.length! > 0) {
        let staffList: StaffMember[] = [];
        let crntAddress = this.dataService.getCrntAddress();
        this.staffs!.forEach((staff) => {
          if (this.geoLocService.isStaffAvailableLocation(crntAddress, staff)) {
            staffList.push(staff);
          }
        });
        this.staffMembers = staffList;
        this.categorizeStaff();
        this.performSearch();
        return;
      }
      this.subscription = this.staffService.getStaff(this.subCategory).subscribe((staffMembers) => {
        let staffList: StaffMember[] = [];
        let crntAddress = this.dataService.getCrntAddress();
        staffMembers.forEach((staff) => {
          if (this.geoLocService.isStaffAvailableLocation(crntAddress, staff)) {
            staffList.push(staff);
          }
        });
        this.staffMembers = staffList;
        this.categorizeStaff();
        this.performSearch(); // Perform initial search when data is loaded
      });
    }
  }

  categorizeStaff() {
    this.categoryBookings = this.dataService.getCategoryBokings() ?? [];
    const staffs = this.staffService.getStaffs(this.staffMembers, this.categoryBookings);
    if (this.preference) {
      this.bookAgainStaff = staffs[0]?.filter(s => !this.staffIds?.includes(s.docId) && !this.isWeeklyOff(s, this.getWeekdayNumber(this.preference?.date)));
      this.nonBookedStaff = staffs[1]?.filter(s => !this.staffIds?.includes(s.docId) && !this.isWeeklyOff(s, this.getWeekdayNumber(this.preference?.date)));
    } else {
      this.bookAgainStaff = staffs[0];
      this.nonBookedStaff = staffs[1];
    }
  }

  performSearch() {
    const searchTerm = this.searchTerm.toLowerCase().trim();

    if (!searchTerm) {
      this.searchResults = this.staffMembers; // No search term, show all staff members
      this.setSelectedStaff(this.searchResults);
      this.showSearchResults = false;
      return;
    }

    this.searchResults = this.staffMembers.filter((staffMember) => {
      return (
        staffMember?.memberName?.toLowerCase()?.includes(searchTerm) ||
        staffMember?.staffAccountId?.toLowerCase()?.includes(searchTerm)
      );
    });
    this.showSearchResults = true;
  }

  selectStaff(staff: StaffMember) {
    if (!this.isStaffAvailableLocation(staff)) return;

    if (this.selectedStaff.length < this.requiredStaffCount) {
      staff.selected = !staff.selected;
      if (staff.selected) {
        this.selectedStaff.push(staff);
      } else {
        const index = this.selectedStaff.indexOf(staff);
        if (index !== -1) this.selectedStaff.splice(index, 1);
      }
    } else {
      let firstSelected = this.selectedStaff.shift();
      if (firstSelected) firstSelected.selected = false;

      staff.selected = true;
      this.selectedStaff.push(staff);
    }
  }

  setSelectedStaff(searchRes: StaffMember[]) {
    if (this.isStaffChecked) return;
    const prevStaff = this.dataService.getSelectedStaffData() ?? [];
    if (prevStaff?.length > 0) {
      prevStaff.forEach((staff: StaffMember) => {
        const index = searchRes.findIndex((res) => res.docId === staff.docId);
        if (index !== -1)
          this.selectStaff(searchRes[index]);
      });
    }
    this.isStaffChecked = true;
    if (this.preference){
      this.selectedStaff = [];
      this.nonBookedStaff?.forEach(s=>s.selected=false);
      this.bookAgainStaff?.forEach(s=>s.selected=false);
    }
  }

  isStaffAvailableLocation(staff: StaffMember): boolean {
    let crntAddress = this.dataService.getCrntAddress();
    let isStaffAvailable = this.geoLocService.isStaffAvailableLocation(crntAddress, staff);
    if (!isStaffAvailable) {
      this.toastr.clear();
      this.toastr.error(
        `${staff.memberName ?? 'This staff member'} is not available on your area!`, 'Something went wrong!',
        { positionClass: 'toast-bottom-right' }
      );
    }
    return isStaffAvailable;
  }

  autoAssign() {
    const data = { action: "AUTO-ASSIGN" };
    this.dataService.changeSelectedStaffData(false);
    this.passBack(data);
  }

  confirm() {

    if (this.selectedStaff.length > 0) {
      const data = { action: "STAFF", selectedStaff: this.selectedStaff };
      if (!this.preference) this.dataService.changeSelectedStaffData(this.selectedStaff);
      this.passBack(data);
    } else {
      this.toastr.clear();
      this.toastr.error(
        `Please select staff!`, 'Something went wrong!',
        { positionClass: 'toast-bottom-right' }
      );
    }
  }

  passBack(data: any) {
    this.passEntry.emit(data);
    this.activeModal.close(data);
  }

  close() {
    this.activeModal.close();
    this.selectedStaff = [];
  }

  private isWeeklyOff(staff?: StaffMember, weekday?: number): boolean {
    return staff?.weeklyOff?.days?.includes(weekday);
  }

  private getWeekdayNumber(dateStr: any): number {
    return new Date(dateStr).getDay();
  }
}
