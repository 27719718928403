import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ErrorLogService {

  constructor(private afs: AngularFirestore) { }

  async logError(data: any) {
    await this.afs.collection('webErrorLogs').doc().set(data, { merge: true });
  }
}
