<div id="snackbar" (click)="hideSnackBar()">
    <div class="d-flex align-items-center">
        <div *ngIf="showSnackbarIcon" class="left-sec">🥳</div>
        <div class="right-sec" [ngClass]="{'center': !showSnackbarIcon}">{{snackBarTxt}}</div>
    </div>
    <div class="close-btn"><img src="assets/images/icons/close-brwn.svg"></div>
</div>
<div class="available-promo-modal">
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
    <div class="available-promo-modal__header d-flex align-items-center justify-content-between" ngbAutofocus>
        <h6 *ngIf="!showActivation">Available Promotions</h6>
        <h6 *ngIf="showActivation">Activate Voucher</h6>
        <a (click)="close()"><img src="assets/images/icons/close-drk.svg"></a>
    </div>

    <div *ngIf="!showActivation" class="modal-body container-fluid">
        <!-- <div *ngIf="voucherConfigData?.isActive && !isSuperVoucherActive"
            class="activate-voucher d-flex justify-content-between align-items-center">
            <div class="left-sec d-flex align-items-center">
                <img src="/assets/images/ticket-discount.webp" alt="">
                <div>
                    <h5>{{voucherConfigData.title}}</h5>
                    <p>{{voucherConfigData.subTitle}}</p>
                </div>
            </div>
            <button (click)="showVoucherActivation()"
                class="d-flex align-items-center justify-content-center">{{voucherConfigData.button}}</button>
        </div>
        <div *ngIf="isSuperVoucherActive" class="voucher-active">
            <div class="top-sec d-flex align-items-center justify-content-center">
                {{'SUPER SAVER VOUCHER ACTIVATED'}}
            </div>
            <div class="bot-sec d-flex align-items-center justify-content-center">
                <img src="/assets/images/ticket-discount.webp" alt="">
                <h5>ENJOY <b>AED 500</b> WORTH OF SAVINGS!</h5>
            </div>
        </div> -->
        <div *ngIf="showRefActivate && !isShowOnly"
            class="ref-activate-item d-flex align-items-center justify-content-between">
            <div class="left-sec d-flex align-items-center">
                <img src="/assets/images/gift.svg" alt="">
                <div>
                    <h5>{{refActivateData?.title}}</h5>
                    <p class="subtitle">{{refActivateData?.subTitle}}</p>
                </div>
            </div>
            <button (click)="activateReferral()"
                class="d-flex align-items-center justify-content-center">Activate</button>
        </div>
        <div *ngFor="let item of discounts; index as i">
            <div class="item-body">
                <div *ngIf="item?.refPromoDetails?.title" class="invite-sec">
                    <h4>{{item?.refPromoDetails?.title ?? 'Refer YellowZap to Friends'}}</h4>
                    <p>{{item?.refPromoDetails?.subTitle ?? 'Help your friends to have an easier life & Get a discount
                        on any bookings'}}</p>
                    <button>
                        <a href="/referral">Invite Friends</a>
                    </button>
                </div>
                <div class="discount-sec">
                    <div class="top-row">
                        <div class="left-sec d-flex">
                            <span [ngClass]="{'disabled' : (item.data?.availableTimes === 0)}">{{item?.data?.code ??
                                item?.code}}</span>
                            <div *ngIf="item?.data?.discount?.subSuperVoucher && item?.data?.availableTimes > 0 && isSuperVoucherActive"
                                class="badge d-flex align-items-center justify-content-center">
                                x{{item.data?.availableTimes}}
                            </div>
                            <div *ngIf="item?.refPromoDetails?.title && item?.data?.availableTimes >= 0"
                                class="badge d-flex align-items-center justify-content-center"
                                [ngClass]="{'badge__disable': item?.data?.availableTimes === 0}">
                                x{{item.data?.availableTimes}}
                            </div>
                            <div *ngIf="!item?.data?.discount?.subSuperVoucher && item?.data?.availableTimes > 0 && !item?.refPromoDetails?.title"
                                class="badge d-flex align-items-center justify-content-center">
                                x{{item.data?.availableTimes}}
                            </div>
                        </div>
                        <a *ngIf="showApplyBtn" (click)="applyPromo(item)"
                            [ngClass]="{'disabled' : (item?.refPromoDetails?.title && item.data?.availableTimes < 1)}">Apply</a>
                        <a *ngIf="!showApplyBtn" class="applicable-chip"
                            [ngClass]="{'copied-btn' : (item?.data?.code === coppiedPromo || item?.code === coppiedPromo)}"
                            (click)="copyPromoCode(item)">{{(item?.data?.code === coppiedPromo || item?.code ===
                            coppiedPromo) ? 'Code Copied' : 'Copy Code'}}</a>
                    </div>
                    <h6 *ngIf="item?.messageDetails?.mainText" class="main-txt">{{item?.messageDetails?.mainText ?? ''}}
                    </h6>
                    <p *ngIf="item?.data?.code === coppiedPromo || item?.code === coppiedPromo" class="copied-txt">
                        Promo applied. The final discounted value will be displayed at the checkout page.</p>
                    <div *ngIf="showDetails(i)" class="more-details">
                        <ul>
                            <li *ngFor="let e of item?.messageDetails?.details">{{e}}</li>
                        </ul>
                    </div>
                    <a *ngIf="item?.messageDetails?.details?.length > 0" class="more-txt"
                        (click)="toggleDetailView(i)">{{showDetails(i) ? "Less Details" : "More Details"}}</a>
                </div>
            </div>
        </div>
        <!-- <div class="item-body" *ngFor="let item of discounts; index as i">
            <div class="top-row">
                <div class="left-sec d-flex">
                    <span>{{item?.data?.code ?? item?.code}}</span>
                    <div *ngIf="item?.data?.discount?.subSuperVoucher && item?.data?.availableTimes > 0 && isSuperVoucherActive"
                        class="badge d-flex align-items-center justify-content-center">x{{item.data?.availableTimes}}
                    </div>
                </div>
                <a *ngIf="showApplyBtn" (click)="applyPromo(item)">Apply</a>
            </div>
            <h6 *ngIf="item?.messageDetails?.mainText" class="main-txt">{{item?.messageDetails?.mainText ?? ''}}</h6>
            <div *ngIf="showDetails(i)" class="more-details">
                <ul>
                    <li *ngFor="let e of item?.messageDetails?.details">{{e}}</li>
                </ul>
            </div>
            <a *ngIf="item?.messageDetails?.details?.length > 0" class="more-txt"
                (click)="toggleDetailView(i)">{{showDetails(i) ? "Less Details" : "More Details"}}</a>
        </div> -->
    </div>

    <div *ngIf="showActivation" class="modal-body container-fluid">
        <div class="activation-body">
            <input type="text" placeholder="Enter Voucher Code" [ngClass]="{'input-error': errorTxt}"
                oninput="let p=this.selectionStart; this.value=this.value.toUpperCase(); this.setSelectionRange(p, p);"
                [(ngModel)]="voucherCode" #ctrl="ngModel" required>
            <span *ngIf="errorTxt" class="error-txt"><img src="/assets/images/icons/info-circle-red.svg"
                    alt="">{{errorTxt}}</span>
            <button (click)="activateVoucher()" [disabled]="showLoader">Activate Voucher
                <div class="loading-spinner" *ngIf="showLoader"></div>
            </button>
        </div>
    </div>

</div>