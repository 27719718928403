import { Injectable } from '@angular/core';
import { ProfileService } from './profile.service';
import { environment } from 'src/environments/environment';
import { FcmPayload } from '../models/fcmPayload.model';
import { Subject } from 'rxjs';
import { DataService } from './data.service';
import firebase from 'firebase/app';
import 'firebase/messaging';

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  isNotificationActive: boolean = false;
  private notificationSource = new Subject<any>();
  message$ = this.notificationSource.asObservable();

  constructor(private profileService: ProfileService, private dataService: DataService) { }

  requestPermission() {
    firebase.messaging().getToken({ vapidKey: environment.firebaseConfig.vapidKey }).then((currentToken) => {
      if (currentToken) {
        let localUid = localStorage.getItem('uid') || '';
        let crntUser = this.dataService.getCrntUser();
        const data = {
          webPushToken: currentToken ?? ''
        }
        if (localUid && crntUser?.phone) {
          this.profileService.updateProfileInfo(localUid, data);
        }
      }
    }).catch((error) => {
      console.log('An error occured while retriving token. ', error);
    });
  }

  listen() {
    firebase.messaging().onMessage((payload?: FcmPayload) => {
      const data = {
        type: 1,
        payload: payload
      }
      this.notificationSource.next(data);
      this.showNotification();
    });
  }

  init() {
    this.requestPermission();
    this.listen();
  }

  deleteToken() {
    firebase.messaging()?.deleteToken();
  }

  showNotification() {
    if (this.isNotificationActive) return;
    var ele = document.getElementById("notification-wrapper");

    if (ele) {
      ele.className = "show";
      this.isNotificationActive = true;
    }

    setTimeout(() => {
      if (ele)
        ele.className = ele.className.replace("show", "hide");
      this.isNotificationActive = false;
    }, 4998);
  }

  hideNotification() {
    if (!this.isNotificationActive) return;
    const ele = document.getElementById("notification-wrapper");
    if (ele) {
      ele.className = ele.className.replace("show", "hide");
      this.isNotificationActive = false;
    }
  }

}
