import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { ErrorLogService } from "src/app/services/error-log.service";
import { environment } from "src/environments/environment";
import { HttpErrorResponse } from "@angular/common/http";
import { ErrorService } from "./error.service";
import firebase from 'firebase/app';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(
        private injector: Injector,
        private router: Router,
        private errorLogService: ErrorLogService
    ) { }

    handleError(error: Error | HttpErrorResponse | any) {

        const errorService = this.injector.get(ErrorService);
        let message;
        let stackTrace;
        let erroType = 'client';

        if (error instanceof HttpErrorResponse) {
            // Server Error
            erroType = 'server';
            message = errorService.getServerMessage(error);
            stackTrace = errorService.getServerStack(error);
        } else {
            // Client Error
            erroType = 'client';
            message = errorService.getClientMessage(error);
            stackTrace = errorService.getClientStack(error);
        }

        console.error('YellowZap Web has thrown an error!', error?.message);
        const res = {
            'errorType': erroType,
            'localUid': localStorage.getItem('uid') ?? null,
            'createdAt': firebase.firestore.Timestamp.now() ?? null,
            'route': this.router.url ?? null,
            'hostName': location?.hostname ?? null,
            'error': error ? {
                message: message ?? null,
                stack: stackTrace ?? null
            } : null,
            'browserInfo': {
                browser: this.getBrowserName() ?? 'unknown',
                userAgent: navigator.userAgent ?? 'unknown',
                // vendor: navigator.vendor ?? 'unknown'
            },
            'version': environment?.webVersion ?? null,
            'os': this.getOSName() ?? null,
            'display': {
                displayWidth: window.outerWidth ?? null,
                displayHeight: window.outerHeight ?? null,
                viewWidth: window.innerWidth ?? null,
                viewHeight: window.innerHeight ?? null
            }
        }
        if (location?.hostname === 'localhost') {
            console.error(error)
            return;
        }
        this.errorLogService.logError(res);
    }

    getOSName(): string {
        var OSName = "Unknown OS";
        if (navigator.userAgent.indexOf("Win") != -1) OSName = "Windows";
        if (navigator.userAgent.indexOf("Mac") != -1) OSName = "Macintosh";
        if (navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";
        if (navigator.userAgent.indexOf("Android") != -1) OSName = "Android";
        if (navigator.userAgent.indexOf("like Mac") != -1) OSName = "iOS";
        return OSName;
    }

    getBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase()
        switch (true) {
            case agent.indexOf('edge') > -1:
                return 'edge';
            case agent.indexOf('opr') > -1 && !!(<any>window).opr:
                return 'opera';
            case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
                return 'chrome';
            case agent.indexOf('trident') > -1:
                return 'ie';
            case agent.indexOf('firefox') > -1:
                return 'firefox';
            case agent.indexOf('safari') > -1:
                return 'safari';
            default:
                return 'other';
        }
    }

}

/* 
// This can be used to trigger ErrorHandler anywhere in the app
throw new Error('This component has thrown an error!');
 */