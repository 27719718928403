import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tnc',
  templateUrl: './tnc.component.html',
  styleUrls: ['./tnc.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class TncComponent implements OnInit {

  @Input() title?: string;
  @Input() tnc: any;
  @Input() content:string="";
  @Input() type: number = 0; // 0: list, 1: paragraphs

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    console.log(this.tnc)
  }

  close() {
    this.activeModal.close();
  }

}
