import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import firebase from 'firebase/app';
import * as moment from 'moment';
import { BehaviorSubject, from, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Address } from '../models/address.model';
import { AvailableDiscounts } from '../models/availableDiscounts';
import { Bookings } from '../models/bookings.model';
import { Discount, IDiscount } from '../models/discount.model';
import { WhatsInclude } from '../models/domain.model';
import { PriceBreakdown } from '../models/priceBreakdown.model';
import { AdditionalTask } from '../models/service.model';
import { MonthlyPackageDetails, SubCategory } from '../models/subCategory.model';
import { User } from '../models/user.model';
import { CcPaymentSuccessComponent } from '../modules/cod-to-cc-payment/cc-payment-success/cc-payment-success.component';
import { PackageBookingComponent } from '../modules/quickbookings/preferred-maid/package-booking/package-booking.component';
import { ActivateReferralComponent } from '../shared/components/activate-referral/activate-referral.component';
import { AddAddressComponent } from '../shared/components/add-address/add-address.component';
import { AvailablePromosComponent } from '../shared/components/available-promos/available-promos.component';
import { ConfirmBookingComponent } from '../shared/components/confirm-booking/confirm-booking.component';
import { JobDetailComponent } from '../shared/components/job-detail/job-detail.component';
import { PriceBreakdownComponent } from '../shared/components/price-breakdown/price-breakdown.component';
import { TncComponent } from '../shared/components/tnc/tnc.component';
import { WhatsIncludeComponent } from '../shared/components/whats-include/whats-include.component';
import { ApiService } from './api.service';
import { CoreService } from './core.service';
import { DataService } from './data.service';
import { SpinnerOverlayService } from './spinner-overlay.service';
import { DialogBoxComponent } from '../shared/components/dialog-box/dialog-box.component';

@Injectable({
  providedIn: 'root'
})
export class QuickBookingService implements OnDestroy {

  private _activeModal!: NgbModalRef;
  // user!: any;
  addresses: Address[] = [];
  subscriptions: Subscription[] = [];
  private $addresses: BehaviorSubject<Array<Address>> = new BehaviorSubject(
    this.addresses
  );
  user!: User;
  private $user: BehaviorSubject<User> = new BehaviorSubject(
    this.user
  );
  isModalOpen: boolean = false;
  uploadTask!: AngularFireUploadTask;
  availableDiscountRes: AvailableDiscounts | undefined = undefined;
  private $availableDiscountRes: BehaviorSubject<AvailableDiscounts | undefined> = new BehaviorSubject(
    this.availableDiscountRes
  )
  availableDiscountSub: Subscription | undefined;
  availableDiscountApiSub: Subscription | undefined;
  availableDiscounts: any;
  crntUserInfo?: User;
  updateCount: number = 0;

  constructor(
    private modalService: NgbModal,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private analyticService: AngularFireAnalytics,
    private apiService: ApiService,
    private dataService: DataService,
    private coreService: CoreService,
    private spinnerService: SpinnerOverlayService) {
    this.availableDiscountSub = this.dataService.crntAvailableDiscounts.subscribe(data => this.availableDiscounts = data);
  }

  ngOnDestroy(): void {
    this.availableDiscountSub?.unsubscribe();
    this.availableDiscountApiSub?.unsubscribe();
  }

  openAddressModal(data?: { currentUI?: string }, addressData?: Address[], currentAddress?: Address, isTempAddress: boolean = false, tempUid?: any, isConfirmationOnly?: boolean) {
    if (!this.isModalOpen) {
      this.isModalOpen = true;
      this._activeModal = this.modalService.open(AddAddressComponent, {
        centered: true,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'address-mobile-view'
      });
      this._activeModal.componentInstance.addressListData = addressData;
      this._activeModal.componentInstance.currentAddress = currentAddress;
      this._activeModal.componentInstance.isTempAddress = isTempAddress;
      this._activeModal.componentInstance.tempUid = tempUid;
      this._activeModal.componentInstance.isConfirmationOnly = isConfirmationOnly;

      if (data && data.currentUI) {
        this._activeModal.componentInstance.currentUI = data.currentUI;
      }
    }
  }

  openPriceBreakdownModal(data?: { currentUI?: string }, priceBreakdownData?: PriceBreakdown[]) {
    if (!this.isModalOpen) {
      this.isModalOpen = true;
      this._activeModal = this.modalService.open(PriceBreakdownComponent, {
        backdrop: 'static',
        centered: true,
        keyboard: false,
        scrollable: true,
      });
      this._activeModal.componentInstance.priceBreakdownData = priceBreakdownData;

      if (data && data.currentUI) {
        this._activeModal.componentInstance.currentUI = data.currentUI;
      }
    }
  }

  openPaymentSucessModal(data?: { currentUI?: string }, bookingData?: Bookings) {
    if (!this.isModalOpen) {
      this.isModalOpen = true;
      this._activeModal = this.modalService.open(CcPaymentSuccessComponent, {
        backdrop: 'static',
        centered: true,
        keyboard: false,
        scrollable: true,
      });
      this._activeModal.componentInstance.booking = bookingData;

      if (data && data.currentUI) {
        this._activeModal.componentInstance.currentUI = data.currentUI;
      }
    }
    setTimeout(() => {
      this.isModalOpen = false;
    }, 1000);
  }

  openConfirmBookingModal(data?: { currentUI?: string }, pageData?: any) {
    if (!this.isModalOpen) {
      this.isModalOpen = true;
      this._activeModal = this.modalService.open(ConfirmBookingComponent, {
        backdrop: 'static',
        centered: true,
        keyboard: false,
        scrollable: true,
      });
      this._activeModal.componentInstance.data = pageData;

      if (data && data.currentUI) {
        this._activeModal.componentInstance.currentUI = data.currentUI;
      }
    }
  }

  openJobDetailModal(data: any) {
    if (!this.isModalOpen) {
      this.isModalOpen = true;
      this._activeModal = this.modalService.open(JobDetailComponent, {
        backdrop: true,
        centered: true,
        keyboard: false,
        scrollable: true,
      });
      this._activeModal.componentInstance.data = data;
      this._activeModal.result.then((res) => {
        if (res?.action) {
          this.dataService.changeCrntJobDetail(res);
        }
      })
        .catch((e) => { })
        .finally(() => this.closeModal());
    }
  }

  openWhatsIncludeModal(data: WhatsInclude[]) {
    if (!this.isModalOpen) {
      this.isModalOpen = true;
      this._activeModal = this.modalService.open(WhatsIncludeComponent, {
        backdrop: true,
        centered: true,
        keyboard: false,
        scrollable: true,
      });
      this._activeModal.componentInstance.data = data;
      this._activeModal.result.then((res) => { }).catch((e) => { }).finally(() => this.closeModal());
    }
  }

  openPackageBookingModal(data: MonthlyPackageDetails, maidServiceInfo: any, otherInfo: any) {
    if (!this.isModalOpen) {
      this.isModalOpen = true;
      this._activeModal = this.modalService.open(PackageBookingComponent, {
        backdrop: true,
        centered: true,
        keyboard: false,
        scrollable: true,
      });
      this._activeModal.componentInstance.data = data;
      this._activeModal.componentInstance.maidServiceInfo = maidServiceInfo;
      this._activeModal.componentInstance.otherInfo = otherInfo;
      this._activeModal.result.then((res) => {
        if (res) {
          this.dataService.changeMaidServiceInfo(res);
        }
      })
        .catch((e) => { })
        .finally(() => this.closeModal());
    }
  }

  closeModal() {
    this._activeModal?.close();
    this.isModalOpen = false;
  }

  getAddress(uid: string) {
    const sub = this.afs.collection('users')
      .doc(uid)
      .valueChanges()
      .subscribe((data: any) => this.updateAddressArray(data?.addresses));
  }

  updateAddressArray(data: Address) {
    this.addresses = [];
    const _addresses = data?.map((a: Address) => a);
    // this.addresses = _addresses;
    this.$addresses.next(_addresses);
  }

  getAddressSubscription() {
    return this.$addresses.asObservable();
  }

  getUserInfo(uid: string) {
    this.updateCount = 0;
    const user = this.afs.collection('users')
      .doc(uid)
      .valueChanges()
      .subscribe((data: any) => this.updateUserInfo(data));
  }

  updateUserInfo(data: User) {
    this.updateCount++;
    if (this.isObjectsEqual(data, this.crntUserInfo)) return;
    this.crntUserInfo = data;
    this.$user.next(data);
  }

  getUserInfoSub() {
    return this.$user.asObservable();
  }

  getBookingData() {
    return this.afs.collection('systemconfig')
      .doc('booking')
      .get();
  }

  getStatusData(id: string) {
    return this.afs.collection('requirement')
      .doc(id)
      .snapshotChanges();
  }

  async getPromo(code: string, subCategoryID: string) {
    this.spinnerService.openLoadingModal();
    const res = await this.afs.collection('discounts', ref =>
      ref.where('id', '==', code.trim().toUpperCase())
        .where('expiredAt', '>=', firebase.firestore.Timestamp.now())
        .where('subCategories', 'array-contains', subCategoryID)
        .limit(1))
      .get().toPromise();
    const discount = res.docs.length > 0 ? new Discount(res?.docs[0]?.data() as IDiscount) : undefined;
    this.spinnerService.closeModal();
    return discount;
  }

  clearUser() {
    this.$user.next({} as User);
  }

  setModalOpenState(state: boolean) {
    this.isModalOpen = state;
  }

  uploadBase64Url(imageUrlList: any[], bookingId: string, isQuickbooking: boolean) {
    let uploadedImageUrlList: any[] = [];
    imageUrlList.forEach(async (imgUrl, i) => {
      const imgName = 'img-0' + i;
      const imgPath = 'bookings/' + bookingId + '/image_' + imgName + '.png';
      this.uploadTask = this.storage
        .ref(imgPath)
        .putString(imgUrl.split(',')[1], "base64", { contentType: 'image/png' });
      (await this.uploadTask).ref.getDownloadURL().then(url => {
        uploadedImageUrlList.push(url);
        if (uploadedImageUrlList.length === imageUrlList.length) {
          this.setAttachments(bookingId, uploadedImageUrlList, isQuickbooking);
        }
      })
    })
  }

  async setAttachments(bookingId: string, data: string[], isQuickbooking: boolean) {
    if (isQuickbooking) {
      await this.afs.collection('requirement').doc(bookingId).update({
        attachments: data ?? [],
        attachmentUploadedAt: firebase.firestore.Timestamp.now()
      });
    } else {
      await this.afs.collection('requirement').doc(bookingId).update({
        enquiryImage: data ?? [],
        attachmentUploadedAt: firebase.firestore.Timestamp.now()
      });
    }
  }

  async uploadAdditionalInstructions(bookingId: string, tasks: AdditionalTask[]) {
    if (!tasks.length) return false;
    const uploadTasks: Promise<string[][]>[] = [];
    for (const [i, task] of tasks.entries()) {
      if (!task.imageFiles.length) continue;
      uploadTasks.push(this.uploadAdditionalBase64Url(task.imageFiles, bookingId, i));
    }
    const results: any[][] = await Promise.all(uploadTasks);
    let additionalTasks: { [key: string]: any }[] = [];
    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];
      task.images = results[i];
      additionalTasks.push(task);
    }
    additionalTasks.forEach(t => {
      if (!t.images) {
        t.images = [];
      }
    })
    const uploaded: boolean = await this.updateInstructionFiles(bookingId, additionalTasks);
    return uploaded;
  }

  async updateInstructionFiles(bookingId: string, additionalTasks: { [key: string]: any }[]) {
    await this.afs.collection('requirement').doc(bookingId).set({
      additionalTasks: additionalTasks ?? [],
      attachmentUploadedAt: firebase.firestore.Timestamp.now()
    }, { merge: true });
    return true;
  }

  private uploadAdditionalBase64Url(imageUrlList: any[], bookingId: string, index: number): Promise<string[][]> {
    return new Promise<any>((resolve, reject) => {
      let uploadedImageUrlList: any[] = [];
      imageUrlList.forEach(async (imgUrl, i) => {
        const imgName = `img-${i}` + index;
        const imgPath = 'bookings/' + bookingId + '/image_' + imgName + '.png';
        this.uploadTask = this.storage
          .ref(imgPath)
          .putString(imgUrl.split(',')[1], "base64", { contentType: 'image/png' });
        (await this.uploadTask).ref.getDownloadURL().then(url => {
          uploadedImageUrlList.push(url);
          if (uploadedImageUrlList.length === imageUrlList.length) {
            resolve(uploadedImageUrlList);
          }
        })
      })
    })
  }

  getAvailableDiscounts(docId?: string, forceChange?: boolean, isMonthly?: boolean) {
    const localUid = localStorage.getItem('uid');
    let req = {
      'platform': 1,
      'subCategoryId': docId ?? null,
      'isMonthly': isMonthly ?? false,
      'userId': localUid ?? null,
      'superVoucher': true,
      'apiVersion': 1.3
    };

    const superVoucherData = this.dataService.getSuperVoucherData();
    const availableVoucherState = this.availableDiscounts?.res?.superVoucherDetails?.activate;
    const newVoucherState = superVoucherData?.superVoucherDetails?.activate;

    if (this.availableDiscounts && this.availableDiscounts.docId === docId && this.availableDiscounts.res && !forceChange
      && this.availableDiscounts.uid === localUid && availableVoucherState === newVoucherState) {
      this.$availableDiscountRes.next(this.availableDiscounts.res);
    } else {
      this.$availableDiscountRes.next(undefined);
      this.availableDiscountApiSub?.unsubscribe();
      this.availableDiscountApiSub = this.apiService.getAvailableDiscounts(req).subscribe(
        async res => {
          this.$availableDiscountRes.next(res);
          this.dataService.changeAvailableDiscounts({ docId: docId, res: res, uid: localUid });
          if (res?.superVoucherDetails?.activate) {
            this.dataService.changeSuperVoucher(res);
          } else {
            this.dataService.changeSuperVoucher(false);
          }
          this.spinnerService.closeModal();
        },
        error => {
          this.spinnerService.closeModal();
          throw error;
        }
      )
    }
  }

  async getUpdatedAvailablePromos(docId: string, forceChange?: boolean, isMonthly?: boolean) {
    const localUid = localStorage.getItem('uid');
    let req = {
      'platform': 1,
      'subCategoryId': docId ?? null,
      'userId': localUid ?? null,
      'superVoucher': true,
      'isMonthly': isMonthly ?? false,
      'apiVersion': 1.3
    };
    const response = await this.apiService.getUpdatedAvailableDiscounts(req);
    return response;
  }

  getDiscountSub(docId?: string) {
    this.getAvailableDiscounts(docId);
    return this.$availableDiscountRes.asObservable();
  }

  openAvailablePromoModal(availableDiscounts: AvailableDiscounts, showApplyBtn: boolean, showActivation?: boolean,
    superVoucherData?: any, voucherConfigData?: any, docId?: string, isShowOnly?: boolean) {
    if (!availableDiscounts) return;
    if (!this.isModalOpen) {
      this.isModalOpen = true;
      const modalRef = this.modalService.open(AvailablePromosComponent, {
        backdrop: true,
        centered: true,
        keyboard: false,
        scrollable: false
      });
      modalRef.componentInstance.availableDiscountRes = availableDiscounts;
      modalRef.componentInstance.showApplyBtn = showApplyBtn;
      modalRef.componentInstance.showActivation = showActivation;
      modalRef.componentInstance.superVoucherData = superVoucherData;
      modalRef.componentInstance.voucherConfigData = voucherConfigData;
      modalRef.componentInstance.isShowOnly = isShowOnly;
      modalRef.componentInstance.docId = docId;
      modalRef.result.then((res) => {
        this.isModalOpen = false;
      })
        .catch((e) => { })
        .finally(() => this.closeModal());
    }
  }

  openActivateReferralModal(refData: any, reopenAvailablePromo?: boolean, activeDocId?: string) {
    if (!this.isModalOpen) {
      this.isModalOpen = true;
      const modalRef = this.modalService.open(ActivateReferralComponent, {
        backdrop: 'static',
        centered: true,
        keyboard: false,
        scrollable: false
      });
      modalRef.componentInstance.refData = refData;
      modalRef.componentInstance.reopenAvailablePromo = reopenAvailablePromo ?? false;
      modalRef.componentInstance.activeDocId = activeDocId;
      modalRef.result.then((res) => {
        this.isModalOpen = false;
      });
    }
  }

  async setAnalyticsInfo(bookingId: string, bookingInfo: any): Promise<void> {
    if (!environment.production || !bookingId) return;
    var ref = this.afs.collection('requirement').doc(bookingId);
    const doc = await ref.ref.get();
    if (doc.exists) {
      const data = doc.data() as any;
      if (!data?.analyticsInfo?.isEventLogged) {
        const params = {
          'booking_id': bookingId,
          'platform': 1, // 0 app, 1 web
          'device': this.coreService.getMobileOperatingSystem(),
          'user_id': bookingInfo?.id ?? 'unknown',
          'phone': bookingInfo?.phone ?? 'unknown',
          'payment': bookingInfo?.payment ?? 'unknown',
          'service_name': bookingInfo?.service_name ?? 'unknown'
        }
        const map = {
          'analyticsInfo': {
            'params': params,
            'isEventLogged': true,
            'createdAt': firebase.firestore.Timestamp.now(),
            'email': bookingInfo?.email ?? 'unknown',
            'pageLoadCount': firebase.firestore.FieldValue.increment(1)
          }
        }
        this.analyticService.logEvent('booking_placed', params);
        await ref.set(map, { merge: true });
      } else {
        const map = {
          'analyticsInfo': {
            'updatedAt': firebase.firestore.Timestamp.now(),
            'pageLoadCount': firebase.firestore.FieldValue.increment(1)
          }
        }
        await ref.set(map, { merge: true });
      }
    }
  }

  verifyVoucherData(configData?: any, availableDiscounts?: AvailableDiscounts, docId?: string) {
    if (configData && availableDiscounts && docId) {
      if (configData.isActive && availableDiscounts.superVoucherDetails?.activate) {
        return;
      }
      const isMonthly = this.dataService.getMaidServiceInfo()?.maidServiceData?.monthlyPackageDetails != null;
      this.getAvailableDiscounts(docId, true, isMonthly);
    }
  }

  getEditType(categoryType: number): number {
    // 1 = Navigate to QB Service Page
    // 2 = Navigate and open edit modal
    const type2CategoryList = [13, 14, 15, 16];
    if (type2CategoryList.includes(categoryType))
      return 2;
    return 1;
  }

  isObjectsEqual(obj1: any, obj2: any) {
    if ((JSON.stringify(obj1) === JSON.stringify(obj2)) && this.updateCount > 3) {
      return true;
    }
    return false;
  }

  canBookWithSelectedTime(subCategory: SubCategory, selectedMin: number): boolean {

    const now: Date = new Date();
    const extraMin: number = subCategory?.serviceInfo?.extraCheckingTime ?? 0;
    let startTime: Date = subCategory?.serviceInfo?.startTime;
    const startTimeDate = moment(`2000-01-01 ${startTime}`, 'YYYY-MM-DD hh.mm A').toDate();
    let endTime: Date = subCategory?.serviceInfo?.endTime;
    const endTimeDate = moment(`2000-01-01 ${endTime}`, 'YYYY-MM-DD hh.mm A').toDate();
    startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), startTimeDate.getHours(), startTimeDate.getMinutes());
    endTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), endTimeDate.getHours(), endTimeDate.getMinutes());

    if (startTime === null || endTime === null || selectedMin === 0) {
      return true;
    }

    const selectedTime: Date = new Date(now.getTime() + (extraMin + selectedMin) * 60000);

    // check if selectedTime is between startTime and endTime
    return now > startTime && selectedTime < endTime;
  }


  openTermsAndConditionsModal(tnc: string[], title: string = "", content: string = "") {
    const modalRef = this.modalService.open(TncComponent, {
      backdrop: 'static',
      centered: true,
      keyboard: false
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.tnc = tnc;
    modalRef.componentInstance.content = content;
  }

  public async getServiceTips(serviceId: string) {
    if (serviceId) {
      const res = await this.afs.collection('subcategories').doc(serviceId).get().toPromise();
      return res.data();
    }
    return null;
  }

  public openDialogBox(icon: string, title: string, desc: string, btnText: string, hasProceedBtn: boolean = false, proceedbtnText?: string, path?: string): Observable<any> {
    const modalRef = this.modalService.open(DialogBoxComponent, {
      backdrop: true,
      centered: true,
      keyboard: false,
      scrollable: true,
      windowClass:'dialog-box'
    });
    Object.assign(modalRef.componentInstance, {
      icon: icon,
      title: title,
      desc: desc,
      buttonText: btnText,
      hasProceedBtn: hasProceedBtn,
      proceedBtnText: proceedbtnText,
      path: path
    });
    return from(modalRef.result);
  }
}
