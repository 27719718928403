<div *ngIf="showNavBar" class="bottom-nav d-sm-none">
    <div class="icon-row">
        <div class="icon-btn" (click)="navigateToPage(0)">
            <img *ngIf="activeIndex !== 0" src="/assets/images/bottom-navbar/home.svg" alt="home icon" loading="eager">
            <img *ngIf="activeIndex === 0" src="/assets/images/bottom-navbar/home-active.svg" alt="home icon"
                loading="eager">
            <span class="icon-txt" [ngClass]="{'txt-active': activeIndex === 0}">Home</span>
        </div>
        <div class="icon-btn" (click)="navigateToPage(1)">
            <img *ngIf="activeIndex !== 1" src="/assets/images/bottom-navbar/services.svg" alt="services icon">
            <img *ngIf="activeIndex === 1" src="/assets/images/bottom-navbar/services-active.svg" alt="services icon">
            <span class="icon-txt" [ngClass]="{'txt-active': activeIndex === 1}">Services</span>
        </div>
        <div class="icon-btn" (click)="navigateToPage(2)">
            <img *ngIf="activeIndex !== 2" src="/assets/images/bottom-navbar/bookings.svg" alt="bookings icon">
            <img *ngIf="activeIndex === 2" src="/assets/images/bottom-navbar/bookings-active.svg" alt="bookings icon">
            <span class="icon-txt" [ngClass]="{'txt-active': activeIndex === 2}">My Bookings</span>
        </div>
        <div class="icon-btn" (click)="navigateToPage(3)">
            <img *ngIf="activeIndex !== 3" src="/assets/images/bottom-navbar/menu.svg" alt="menu icon" loading="eager">
            <img *ngIf="activeIndex === 3" src="/assets/images/bottom-navbar/menu-active.svg" alt="menu icon"
                loading="eager">
            <span class="icon-txt" [ngClass]="{'txt-active': activeIndex === 3}">More</span>
        </div>
    </div>
</div>