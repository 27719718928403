import { Address } from "src/app/models/address.model";

export function removePlusCode(address: string): string {
    if (!address) return address;
    let pattern: RegExp = /\w+\+\w+/;
    return address.replace(pattern, "").replace(/^\s*-\s*/, "");
}

export function getFormatedAddress(address: Address): string {
    const addressComponents = address?.placeDetails?.addressComponents;
    const componentsLength = addressComponents?.length;
    const placeName = address?.placeDetails?.name ? (address?.placeDetails?.name + ' - ') : '';
    const location = address?.location;
    var plusCode = '';
    for (let i = 0; i < componentsLength; i++) {
        for (let j = 0; j < addressComponents[i]?.types?.length; j++) {
            const type = addressComponents[i]?.types[j];
            if (type === 'plus_code') {
                const component = addressComponents[i];
                plusCode = component?.long_name ?? component?.short_name ?? '';
            }
            if (plusCode) {
                var formattedLoc = null;
                formattedLoc = location.replace((plusCode + ' - '), '');
                formattedLoc = formattedLoc.replace(plusCode, '');
                return placeName + formattedLoc ?? location ?? placeName;
            }
        }
    }
    return placeName + (address?.placeDetails?.formattedAddress ?? '');
}