import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  isSnackBarActive: boolean = false;

  constructor(private dataService: DataService) { }

  showSnackBar(data: any) {
    if (this.isSnackBarActive || !data?.text) return;
    this.dataService.changeCrntSnackbarInfo(data);
    var ele = document.getElementById("yc-snackbar");

    if (ele) {
      ele.className = "show";
      this.isSnackBarActive = true;
    }

    setTimeout(() => {
      if (ele)
        ele.className = ele.className.replace("show", "hide");
      this.isSnackBarActive = false;
    }, 2998);
  }

  hideSnackBar() {
    if (!this.isSnackBarActive) return;
    const ele = document.getElementById("yc-snackbar");
    if (ele) {
      ele.className = ele.className.replace("show", "hide");
      this.isSnackBarActive = false;
    }
  }
}
