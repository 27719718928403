import { Directive, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import clevertap from 'clevertap-web-sdk';

declare global {
  interface Window {
    dataLayer: any;
    gtmDidInit: boolean;
    clevertapDidInit: boolean;
  }
}
window.dataLayer = window.dataLayer || [];

@Directive({
  selector: '[gtmScript]'
})
export class GtmScriptDirective {

  constructor() { }

  @HostListener('mousemove', ['$event']) onMouseMove($event: any) {
    this.initGTMOnEvent($event);
  }

  @HostListener('touchstart', ['$event']) onTouchStart($event: any) {
    this.initGTMOnEvent($event);
  }

  initGTMOnEvent(event: any) {
    this.initGTM();
    this.initClevertap();
    event.currentTarget.removeEventListener(event.type, this.initGTMOnEvent); // remove the event listener that got triggered
  }

  initGTM() {
    if (window.gtmDidInit) {
      return;
    } else {
      window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.onload = () => {
        window.dataLayer.push({ event: 'gtm.js', 'gtm.start': new Date().getTime(), 'gtm.uniqueEventId': 0 });
      }; // this part ensures PageViews is always tracked
      script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-NHBW9LT';
      document.head.appendChild(script);
    }
  }

  initClevertap() {
    if (window.clevertapDidInit) {
      return;
    } else {
      window.clevertapDidInit = true;
      clevertap.init(environment.clevertapId);
    }
  }

}
