<!-- Login/Signup Modal -->
<!-- <div id="recaptcha-container" class="d-none"></div> -->
<div class="yc-modal" *ngIf="!isOtpSent && !isOtpResend">
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
    <div class="yc-modal__header d-flex align-items-center justify-content-between">
        <!-- <button type="button" class="close" aria-label="Close" (click)="closeModal()"></button> -->
        <span></span>
        <h6>Your Phone Number</h6>
        <a (click)="closeModal()"><img src="assets/images/icons/close-brwn.svg"></a>
    </div>
    <div class="yc-modal__body">
        <p class="text-center">Enter your mobile number to Login/Sign Up</p>
        <!-- <h4>Mobile Number</h4> -->
        <form action="" [formGroup]="phoneForm">
            <div class="d-flex justify-content-center w-100">
                <a class="flag-ico"><img src="{{getFlagUrl()}}"></a>
                <select name="countryCode" id="country-code" formControlName="countryCode">
                    <option value="+971">+971</option>
                    <!-- <option value="+91">+91</option> -->
                    <option value="+94">+94</option>
                    <option value="+61">+61</option>
                </select>
                <input oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" type="text"
                    inputmode="numeric" placeholder="12 345 6789" name="phoneNoCtrl" formControlName="phoneNumber"
                    maxlength="10">
            </div>
            <div *ngIf="phoneForm.invalid && phoneSubmitted">
                <label class="error-txt">*Please enter a valid phone number!</label>
            </div>
            <p class="hint-txt">YellowZap will send you an SMS with verification code.</p>
            <div class="d-flex justify-content-center">
                <button class="yc-btn" (click)="sendOtp()" [disabled]="showLoader">Get OTP
                    <div class="loading-spinner" *ngIf="showLoader"></div>
                </button>
            </div>
        </form>
        <!-- <div id="recaptcha-container" style="display: none;"></div> -->
        <!-- <div class="loader" *ngIf="showLoader"></div> -->
    </div>
</div>

<!-- OTP Modal -->
<div class="yc-modal" *ngIf="(isOtpSent && showOtpScreen) || isOtpResend">
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
    <div class="yc-modal__header d-flex align-items-center justify-content-between">
        <!-- <button type="button" class="close" aria-label="Close" (click)="closeModal()"></button> -->
        <span></span>
        <h6>{{welcomeText}}</h6>
        <a (click)="closeModal()"><img src="assets/images/icons/close-brwn.svg"></a>
    </div>
    <div class="yc-modal__body yc-modal__body--otp">
        <p class="text-center">Enter the 6 digit code we sent to</p>
        <p class="phone-txt">{{ phoneNumber ? phoneNumber : '+971234567890' }}<strong
                (click)="changeNumber()">Change?</strong></p>
        <!-- <h4 class="text-center">{{ welcomeText }}</h4> -->
        <!-- <a class="d-flex align-items-center justify-content-between">
            <p class="m-0">{{ phoneNumber }}</p>
            <span (click)="changeNumber()" style="cursor: pointer;">Change?</span>
        </a> -->
        <form action="">
            <div class="d-flex justify-content-center otp-input-row">
                <!-- <div *ngFor="let digit of otpDigits; index as i">
                    <input type="text" #otpFormRow formControlName="{{ digit }}" inputmode="numeric" maxlength="1"
                        (keyup)="keyUpEvent($event, i)"
                        oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');">
                </div> -->
                <!-- <ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)"
                    (fill)="handleFillEvent($event)">
                </ngx-otp-input> -->
                <!-- <ng-otp-input  (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input> -->
                <input (ngModelChange)="onOtpChange($event)" class="otp-input" type="text" name="otp" maxlength="6"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                    [(ngModel)]="otpString" appAutofocus />
            </div>
            <div *ngIf="showError && submitted">
                <label class="error-txt">*Please complete all required fields!</label>
            </div>
            <div class="d-flex justify-content-center">
                <button type="submit" class="yc-btn" (click)="verifyOtp()" [disabled]="showLoader">Verify OTP
                    <div class="loading-spinner" *ngIf="showLoader"></div>
                </button>
            </div>
        </form>
        <!-- <div id="recaptcha-container" class="d-none"></div> -->
        <!-- <div class="loader" *ngIf="showLoader"></div> -->
        <p class="tnc-txt">By Verifying, you are agreeing to our <strong (click)="openTncPage()">Terms &
                Conditions.</strong></p>
    </div>
    <div class="yc-modal__footer yc-modal__footer--otp">
        <p [ngClass]="{'txt-disabled' : isDisableResend}" class="resend-txt">Didn’t Receive an OTP?
            <strong (click)="isDisableResend ? null : resendOtp()">Resend OTP</strong>
        </p>
        <p class="counter-txt" *ngIf="isDisableResend">{{ counter | countdownFormat }}</p>
        <!-- <div>
            <p [ngClass]="{'txt-disabled' : isDisableResend}" class="m-0">Didn’t Receive an OTP Yet ?</p>
        </div> -->
        <!-- <div class="d-flex flex-row align-items-center">
            <a [ngClass]="{'txt-disabled' : isDisableResend}" style="cursor: pointer;"
                (click)="isDisableResend ? null : resendOtp()">Resend OTP</a>
            <p class="m-0">
                {{ counter | countdownFormat }}
                <countdown #countdown [config]="{ leftTime: 30, format: 's' }" (event)="onTimerFinished($event)">
                </countdown> Seconds
            </p>
        </div> -->
    </div>
</div>
