import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Promotion } from 'src/app/models/promotion.model';
import { PromotionService } from 'src/app/services/promotion.service';
import { RouteService } from 'src/app/services/route.service';

@Component({
  selector: 'app-promo-details',
  templateUrl: './promo-details.component.html',
  styleUrls: ['./promo-details.component.scss']
})
export class PromoDetailsComponent implements OnInit {

  @Input() public promo?: Promotion;
  isQuickBooking: boolean = false;
  code: string = '';
  categoryType: number = 0;

  constructor(
    private promotionService: PromotionService,
    private router: Router,
    private routeService: RouteService
  ) { }

  ngOnInit(): void {
    this.isQuickBooking = this.promo?.subCategory?.isQuickBooking ?? false;
    this.code = this.promo?.subCategoryDetails?.code ?? '';
    this.categoryType = this.promo?.subCategoryDetails?.newType ?? 0;
  }

  /**
   * close promotion modal
   * @return void
   */
  closeModal() {
    this.promotionService.closeModal();
  }

  showInterest() {
    this.router.navigate(['/post-task/' + this.promo?.subCategory?.id + '/promotion']);
    this.closeModal();
  }

  bookNow() {
    const route = this.routeService.getQBLandRouteLink(this.code, this.categoryType);
    this.router.navigate([route]);
    this.closeModal();
  }

}
