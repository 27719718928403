import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit, OnDestroy {

  snackbarInfoSub?: Subscription;
  icon: string = '';
  text: string = '';

  constructor(private dataService: DataService) {
    this.snackbarInfoSub = this.dataService.crntSnackbarInfo.subscribe(data => this.setSnackbarInfo(data));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.snackbarInfoSub?.unsubscribe();
  }

  setSnackbarInfo(data: any) {
    this.icon = data?.icon ?? '';
    this.text = data?.text ?? '';
  }

}
