<div class="custom-popover">
  <h6>
    Our service features depends
    on location
  </h6>
  <div class="popover-btn" (click)="onAction$('add')">
    <a>Add now</a>
  </div>
  <div class="popover-btn mb-0" (click)="onAction$('skip')">
    <a>
      Skip and add later</a>
  </div>
</div>
