import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private dataService: DataService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.authService.isLoggedIn) {
      return true;
    } else {
      const staffId = route.queryParamMap.get('staffId');
      if (staffId && !this.dataService.getStaffRatingId()) {
        this.router.navigate(['/dubai']);
        this.dataService.changeStaffRatingId(staffId);
      }
    }
    this.authService.openModal();
    return false;
  }

}
