import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "countdownFormat"
})
export class CountdownFormatPipe implements PipeTransform {
    transform(value: number): string {
        const minutes: number = Math.floor(value / 60);
        return (
            ("00" + Math.floor(value - minutes * 60)).slice(-2)
            + (Math.floor(value - minutes * 60) === 1 ? " Second " : " Seconds")
        );
    }
}
