import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WhatsInclude } from 'src/app/models/domain.model';

@Component({
  selector: 'app-whats-include',
  templateUrl: './whats-include.component.html',
  styleUrls: ['./whats-include.component.scss']
})
export class WhatsIncludeComponent implements OnInit {

  @Input() data: WhatsInclude[] = [];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }


}
