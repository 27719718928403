import { Component, Input, OnInit } from '@angular/core';
import { PriceBreakdown } from 'src/app/models/priceBreakdown.model';
import { QuickBookingService } from 'src/app/services/quick-booking.service';

@Component({
  selector: 'app-price-breakdown',
  templateUrl: './price-breakdown.component.html',
  styleUrls: ['./price-breakdown.component.scss']
})
export class PriceBreakdownComponent implements OnInit {
  priceBreakdownList: PriceBreakdown[] = [];

  @Input()
  public priceBreakdownData!: PriceBreakdown[];

  constructor(private quickbookingServcice: QuickBookingService) { }

  ngOnInit(): void {
    this.priceBreakdownList = this.priceBreakdownData;
  }

  closeModal() {
    this.quickbookingServcice.closeModal();
  }

}
