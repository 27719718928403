<div class="outer-div">

    <div class="landing-banner-modal">
        <div class="modal-header">
            <h5 class="modal-title w-100">{{bannerTitle}}</h5>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()"></button>
        </div>
    
        <div class="banner-img">
            <figure>
                <img *ngIf="bannerImage" src="{{bannerImage}}" alt="banner-image">
            </figure>
        </div>
    
        <div class="modal-body container-fluid div">
            <p class="break-field">{{bannerContent}}</p>
        </div>
        <div class="modal-footer">
            <button class="interest-btn" (click)="enquireNow()">Enquire Now</button>
        </div>
    </div>
</div>