import { Address } from "./address.model";
import { AdminDetails } from "./adminDetails";

export interface IUser {
  uid: string;
  displayName: string;
  email: string;
  phone: string;
  type: string;
  accountId: number;
  createdAt: firebase.default.firestore.Timestamp;
  isWebUser: boolean;
  photoUrl: string;
  tncYc: boolean;
  title: string;
  createdBy?: User;
  countryCode: string;
  addresses: [Address];
  promoDetails: any;
  times: number;
  adminDetails: AdminDetails;
  businessDetails: any;
  webPushToken: string;
  source: string;
  agentName: string;
  staffDetails: any
}

export class User {
  [x: string]: any;
  uid?: string;
  displayName: string;
  email: string;
  phone: string;
  type: string;
  accountId: number;
  createdAt: firebase.default.firestore.Timestamp;
  isWebUser: boolean;
  createdBy?: User;
  photoUrl: string;
  tncYc: boolean;
  title: string;
  countryCode: string;
  addresses: [Address];
  promoDetails: any;
  times: number;
  adminDetails: AdminDetails;
  businessDetails: any;
  webPushToken: string;
  source: string;
  agentName: string;

  constructor(data: IUser, id?: string) {
    this.uid = data?.uid ? data.uid : id;
    this.displayName = data.displayName;
    this.email = data.email;
    this.createdBy = data.createdBy;
    this.phone = data.phone;
    this.type = data.type;
    this.accountId = data.accountId;
    this.createdAt = data.createdAt;
    this.isWebUser = data.isWebUser;
    this.photoUrl = data.photoUrl;
    this.tncYc = data.tncYc;
    this.title = data.title;
    this.countryCode = data.countryCode;
    this.addresses = data.addresses;
    this.promoDetails = data.promoDetails;
    this.times = data.times;
    this.adminDetails = data.adminDetails;
    this.businessDetails = data.businessDetails;
    this.webPushToken = data.webPushToken;
    this.source = data.source;
    this.agentName = data.agentName;
  }
}
