import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss']
})
export class ContactDetailComponent implements OnInit, OnDestroy {
  user!: User;
  localUid!: string;
  profileForm!: FormGroup;
  titles: string[] = ['Mr', 'Miss', 'Mrs', 'Ms'];
  countryCodes: string[] = ['+971', '+91', '+94', '+61'];
  title!: string;
  code!: string;
  showLoader: boolean = false;
  disabled: boolean = true;
  submitted: boolean = false;
  isLoggedin: boolean = false;
  subscription: Subscription | undefined;
  phone: string = '';

  constructor(
    private activeModal: NgbActiveModal,
    private profileService: ProfileService,
    private fb: FormBuilder,
    private authService: AuthService,
    private dataService: DataService,
    private toastr: ToastrService

  ) { }

  async ngOnInit(): Promise<void> {
    this.showLoader = true;
    this.isLoggedin = this.authService.isLoggedIn;
    this.subscription = this.dataService.crntIsLoggedIn.subscribe(isLoggedIn => this.isLoggedin = isLoggedIn);
    this.profileForm = this.fb.group({
      title: ['Mr', Validators.required],
      displayName: ['', Validators.required],
      countryCode: ['+971', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });

    await this.setProfileInfo();
    this.showLoader = false;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  async setProfileInfo() {
    this.localUid = localStorage.getItem('uid') || '';
    if (this.localUid !== '') {
      this.user = await this.profileService.getUser(this.localUid);
      this.phone = this.user.phone.replace(/\+91|\+971|\+61|\+94/, '');
      this.title = this.user.title || 'Mr';
      this.code = this.user.countryCode || '+971';

      this.profileForm = this.fb.group({
        title: [this.title, Validators.required],
        displayName: [this.user.displayName, [Validators.required, Validators.minLength(2)]],
        countryCode: [this.code, Validators.required],
        email: [this.user.email || '', [Validators.required, Validators.email]]
      });
    }
  }

  async updateInfo() {
    this.showLoader = true;
    this.submitted = true;
    if (this.profileForm.invalid) {
      this.showLoader = false;
      return;
    }

    else if (this.profileForm.valid) {
      let data = {};
      data = {
        displayName: this.profileForm.value.displayName,
        email: this.profileForm.value.email,
        title: this.profileForm.value.title
      }
      await this.profileService.updateProfileInfo(this.localUid, data);
      this.dataService.changeProfileName(this.profileForm.value.displayName);
      this.showLoader = false;
      this.close();
    }
  }

  get fval() {
    return this.profileForm.controls;
  }

  get errorMessage() {
    if (this.profileForm.controls.email.errors) return 'Please enter valid email!'
    else
      return 'Please complete all required fields!'
  }

  close() {
    this.activeModal.close();
  }

}
