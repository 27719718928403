<div class="contact-detail-modal">
    <div class="contact-detail-modal__header">
        <h6>Contact Details</h6>
        <a (click)="close()"><img src="assets/images/icons/close-brwn.svg"></a>
    </div>

    <div class="contact-detail-modal__body">
        <div class="progress-bar-new-wrapper">
            <ul class="progress-bar-new">
                <li class="complete">Phone Number</li>
                <li class="active">Your Name</li>
                <li class="inactive">Your Address</li>
            </ul>
        </div>

        <div class="detail-form">
            <form action="" class="" [formGroup]="profileForm">

                <div class="d-flex flex-column mb-3">
                    <label class="form-label">Your Name</label>
                    <div class="d-flex">
                        <select formControlName="title" [(ngModel)]="title">
                            <option id="selectedTitle" *ngFor="let title of titles;" [value]="title">{{
                                title }}</option>
                        </select>
                        <input type="text" formControlName="displayName" class="form-control"
                            placeholder="What should we call you?"
                            [ngClass]="{ 'is-invalid': submitted && fval.displayName.errors }">
                    </div>
                    <div *ngIf="submitted && fval.displayName.errors" class="invalid-feedback d-flex">
                        <div *ngIf="fval.displayName?.errors?.required" class="validation-text">* Please
                            enter your name</div>
                        <div *ngIf="fval.displayName?.errors?.minlength" class="validation-text">* Please
                            enter a valid name</div>
                    </div>
                    <p class="hint-txt">This will help us to know how to greet you.</p>
                </div>

                <div class="d-flex flex-column">
                    <label class="form-label">Your Email</label>
                    <input type="email" inputmode="email" formControlName="email" class="w-100" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && fval.email.errors }" placeholder="example@mail.com">
                </div>
                <div *ngIf="submitted && fval.email.errors" class="invalid-feedback d-flex">
                    <div *ngIf="fval.email?.errors?.required" class="validation-text">* Please
                        enter your email</div>
                    <div *ngIf="fval.email?.errors?.email" class="validation-text">* Please
                        enter a valid email</div>
                </div>
                <p class="hint-txt">This will help you to get the receipt and records.</p>

                <div class="mobile-no">
                    <div class="lbl">Your Mobile Number<img src="/assets/images/icons/verify-sm-gn.svg"></div>
                    <div class="phone-box">
                        <span class="number">{{code}}{{phone}}</span>
                        <span class="verify-txt">VERIFIED</span>
                    </div>
                </div>

            </form>
        </div>
    </div>

    <div class="contact-detail-modal__footer">
        <button type="submit" [disabled]="showLoader" (click)="updateInfo()">Continue
            <div class="loading-spinner" *ngIf="showLoader"></div>
        </button>
    </div>

</div>