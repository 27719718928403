<div class="package-booking-modal">
    <div class="package-booking-modal__header ">
        <div class="d-flex align-items-center justify-content-between mb-2">
            <h6 class="service">
                {{otherInfo?.count}} Maid - {{otherInfo?.hours}}
            </h6>
            <a (click)="close()"><span>Close</span><img src="assets/images/icons/close-brwn.svg"></a>
        </div>
        <div class="d-flex align-items-center justify-content-between switch-container">
            <h6>
                Package Booking
            </h6>
            <div class="form-check form-switch me-auto ms-2">
                <input class="form-check-input mt-0" type="checkbox" role="switch" id="package-checkbox"
                    [checked]="isActive" (click)="disableMonthlyBooking($event)">
                <label class="form-check-label" for="package-checkbox"></label>
            </div>
        </div>
        <!-- <hr>
        <div class="item-body">
            <div class="form-check d-flex p-0">
                <div *ngFor="let package of data?.packages; let i=index;">
                    <input type="radio" class="btn-check" [name]="package?.packageName!" [id]="'p'+i" autocomplete="off"
                        (click)="onPackageSelection(package)" [value]="package.itemId" [(ngModel)]="packageId">
                    <label class="btn btn-outline-success btn--packages" [for]="'p'+i">{{package?.packageName}}</label>
                </div>
            </div>
        </div> -->
        <hr class="mb-0">
    </div>
    <div class="modal-body container-fluid">
        <div class="item-body">
            <label for="" class="form-label">Select Your Weekly Service Frequency</label>
            <div class="form-check ps-0">
                <ng-container *ngFor="let frequency of data?.frequencies; let i=index">
                    <div *ngIf="frequency.isActive" class="d-flex item">
                        <input type="radio" class="btn-check" [name]="frequency?.itemName!" [id]="'f'+i"
                            autocomplete="off" [value]="frequency.itemId" [(ngModel)]="frequencyId"
                            (change)="onFrequencySelection()">
                        <label class="btn btn-outline-success btn--packages"
                            [for]="'f'+i">{{frequency?.itemName}}</label>
                        <div class="frequency">
                            <p>
                                <strong class="price">AED {{getFrequencyItemPrice(selectedPackage,frequency)}}</strong>
                                <span class="discount"
                                    *ngIf="getFrequencyPriceDetails(frequency.itemId)?.discount>0">{{getFrequencyPriceDetails(frequency.itemId)?.discountText}}</span>
                            </p>
                            <p class="desc">{{getFrequencySubText(selectedPackage,frequency)}}</p>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div *ngIf="isPackageChanged || !maidServiceInfo?.maidServiceData?.monthlyPackageDetails"
        class="modal-footer d-flex align-items-center justify-content-between">
        <button type="button" class="yc-btn" (click)="confirm()">Confirm</button>
    </div>
</div>