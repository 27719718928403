import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PerformanceService {

  constructor() { }

  getNavigationType() {
    // navigation types: 'reload', 'navigate', 'back_forward', 'prerender'
    const navigationType = (window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming).type;
    return navigationType;
  }
}
