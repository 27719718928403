import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from 'src/environments/environment';
import { DiscountCode } from '../models/discountCode.model';
import { AvailableDiscounts } from '../models/availableDiscounts';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private dataService: DataService
  ) { }

  processPayment(data: any) {
    const url = environment.paymentUrl;
    return this.http.post<any>(url, data);
  }

  getPaymentStatus(bookingId: string) {
    const url = environment.paymentStatusUrl;
    const params = new HttpParams().set('bookingId', bookingId);
    return this.http.get<any>(url, { params });
  }

  createPayment(data: any) {
    const url = environment.stripePaymentUrl;
    return this.http.post<any>(url, data);
  }

  makePayment(data: any) {
    const url = environment.stripeMakePaymentUrl;
    return this.http.post<any>(url, data);
  }

  getGeocodeData(latLng: string) {
    const url = environment.mapGeocodeUrl;
    const key = environment.googleMapKey;
    const params = new HttpParams().set('latlng', latLng).set('key', key).set('language', 'en');
    return this.http.get<any>(url, { params });
  }

  submitContactYello(data: any) {
    const url = environment.contactYello;
    return this.http.post<any>(url, data);
  }

  getDiscount(data: any) {
    const url = environment.discountUrl;
    return this.http.post<DiscountCode>(url, data);
  }

  getAvailableDiscounts(data: any) {
    this.dataService.changeDisableSpinner(true);
    const url = environment.availableDiscountsUrl;
    return this.http.post<AvailableDiscounts>(url, data);
  }

  activateReferralCode(data: any) {
    const url = environment.discountUrl;
    return this.http.post<{ status: string, message: string }>(url, data);
  }

  generateReferralCode(data: any) {
    const url = environment.generateReferralCodeUrl;
    return this.http.post<any>(url, data);
  }

  async getUpdatedAvailableDiscounts(data: any) {
    const url = environment.availableDiscountsUrl;
    const response = await this.http.post<AvailableDiscounts>(url, data).toPromise();
    return response;
  }

  getRecentBooking(data: any) {
    const url = environment.recentBookingReviewUrl;
    return this.http.post<any>(url, data);
  }

  checkStaffAvailability(data:any){
    const url = environment.staffAvailability;
    return this.http.post<any>(url, data);
  }
}
