import { NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';

import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AddAddressComponent } from './shared/components/add-address/add-address.component';
import { PriceBreakdownComponent } from './shared/components/price-breakdown/price-breakdown.component';
import { SpinnerOverlayComponent } from './shared/components/spinner-overlay/spinner-overlay.component';
// import { GoogleMapsModule } from '@angular/google-maps';
import { NgxStripeModule } from 'ngx-stripe';
import { AddressPageComponent } from './modules/address-page/address-page.component';
// import { NgxPaginationModule } from 'ngx-pagination';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { PixelModule } from 'ngx-pixel';
import { BannerInfoComponent } from './shared/components/banner-info/banner-info.component';
import { EditPriceComponent } from './shared/components/edit-price/edit-price.component';
import { LandingBannerComponent } from './shared/components/landing-banner/landing-banner.component';
import { TimeExpireComponent } from './shared/components/time-expire/time-expire.component';
import { GtmScriptDirective } from './shared/directives/gtm-script.directive';
import { TwoDecimalPlaceDirective } from './shared/directives/two-decimal-place.directive';

@NgModule({
  declarations: [
    AppComponent,
    AddAddressComponent,
    SpinnerOverlayComponent,
    PriceBreakdownComponent,
    AddressPageComponent,
    TimeExpireComponent,
    BannerInfoComponent,
    EditPriceComponent,
    TwoDecimalPlaceDirective,
    LandingBannerComponent,
    GtmScriptDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({ preventDuplicates: true,closeButton:true}),
    HttpClientModule,
    // GoogleMapsModule,
    NgxStripeModule.forRoot(environment.stripe_pk),
    // NgxPaginationModule,
    // SharedModule
    PixelModule.forRoot({ enabled: environment.production ? true : false, pixelId: environment.pixelID })
  ],
  providers: [
    AngularFirestore,
    Meta
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
