import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, Subscription, throwError } from 'rxjs';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay.service';
import { catchError, finalize, retry } from 'rxjs/operators';

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {

  constructor(private readonly spinnerOverlayService: SpinnerOverlayService) { }

  // intercept(
  //   request: HttpRequest<any>,
  //   next: HttpHandler): Observable<HttpEvent<any>> {
  //   const spinnerSubscription: Subscription = this.spinnerOverlayService.spinner$.subscribe();
  //   return next
  //     .handle(request)
  //     .pipe(retry(1), finalize(() => spinnerSubscription.unsubscribe()),
  //       // .pipe(
  //       //   finalize(() => spinnerSubscription?.unsubscribe()),
  //       catchError((error: HttpErrorResponse) => {
  //         if (error.status === 401) {
  //           // refresh token
  //           return throwError(error);
  //         } else {
  //           return throwError(error);
  //         }
  //       })
  //     )
  // }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const spinnerSubscription: Subscription = this.spinnerOverlayService.spinner$.subscribe();
    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Handle 401 error, e.g., refresh token logic
          return throwError(error);
        }
        return throwError(error);
      }),
      finalize(() => {
        if (spinnerSubscription && !spinnerSubscription.closed) {
          spinnerSubscription.unsubscribe();
        }
      })
    );
  }
}
